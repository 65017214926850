import React, { useState, useEffect, useRef } from 'react';

function ProfileIcon({ dropDownIsSelected, setDropDownIsSelected, userInfo, initialLetter, dropdownOpen, setDropdownOpen, profileImageRef }) {
    const [profileImage, setProfileImage] = useState(null);

    return (
        <>
        {userInfo.userProfilePicture === null ? (
            <div
                ref={profileImageRef}
                className={`flex-initial align-middle`}
                onClick={() => {setDropDownIsSelected(!dropDownIsSelected);
                }}
            >
                <h1 className={`flex items-center justify-center ${
                    dropdownOpen ? "outline outline-indigo-900" : "hover:outline hover:outline-indigo-900"
                } text-center text-2xl bg-amber-400 h-14 w-14 rounded-full`}>{initialLetter}</h1>
            </div>
        ) : (
            <div
            ref={profileImageRef}
            className={`flex-initial align-middle`}
            onClick={() => {setDropDownIsSelected(!dropDownIsSelected);
            }}
        >
            <img className={`flex items-center justify-center ${
                dropdownOpen ? "outline outline-indigo-900" : "hover:outline hover:outline-indigo-900"
            } text-center text-2xl bg-amber-400 h-14 w-14 rounded-full`} src={`https://tmbi.s3.us-east-1.amazonaws.com/${userInfo.userProfilePicture}`}/>
        </div>
        )}
        </>
    );
}

export default ProfileIcon;
