import React, { useState, useEffect } from 'react';
import MyIdeas from '../idea/myIdeas';
import Idea from '../idea/idea';

function MyIdeasDashboard({setSelectedIdea, ideas, setIdeas, selectedIdea, subIdeas, setSubIdeas}) {
    return ( 
        <div className='w-full h-screen/1 lg:w-5/6 mx-auto mt-3'>

        { selectedIdea ? (
            <Idea selectedIdea={selectedIdea} setSelectedIdea={setSelectedIdea} subIdeas={subIdeas} setSubIdeas={setSubIdeas} />
            ) : ( <MyIdeas
                setSelectedIdea={setSelectedIdea}
                ideas={ideas}
                setIdeas={setIdeas}
                subIdeas={subIdeas}
                setSubIdeas={setSubIdeas}
                 />
            )  
        }

    </div>
     );
}

export default MyIdeasDashboard;