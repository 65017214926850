import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container  w-5/6 mx-auto text-black  p-2 rounded-md">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p className='mb-4 font-semibold'> Effective Date: May 15, 2023</p>
            <p className="mb-4">Welcome to our website. We respect the privacy of our users and are committed to protecting it. This Privacy Policy explains what information we collect from you, how we use it, and your rights in relation to it. Please read this Privacy Policy carefully before using our website.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">1. Information We Collect</h2>
            <p className="mb-4">We collect only the necessary information for the functionality of the website. This includes your email and username. We do not collect any other personally identifiable information unless explicitly provided by you.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">2. Use of Information</h2>
            <p className="mb-4">The information we collect is used to ensure the functionality of the website and to improve the performance of our tools. We do not distribute user data to any third parties.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">3. Passwords</h2>
            <p className="mb-4">We use advanced cryptographic hashing methods to securely store your password. Our system is designed so that neither we nor anyone else can access your original password. This technology ensures maximum security for your personal information </p>

            <h2 className="text-xl font-bold mt-4 mb-2">4. Your Rights</h2>
            <p className="mb-4">You have the right to access, modify, or delete your personal information at any time. Please contact us if you would like to exercise these rights.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">5. Changes to Privacy Policy</h2>
            <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace this Privacy Policy at any time. If a revision is material we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">6. Contact Us</h2>
            <p className="mb-4">If you have any questions about this Privacy Policy, please contact us.</p>

            <p className="mt-6">By using our services, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy.</p>

            </div>
    );
};

export default PrivacyPolicy;