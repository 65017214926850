import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Landing from "./components/landing/landing";
import Dashboard from "./components/dashboard/dashboard";
import reportWebVitals from "./reportWebVitals";
import CompleteSignUp from "./components/auth/completeSignUp";
import ProtectedRoute from "./components/auth/protectedRoute";
import { AuthProvider } from "./context/AuthContext";
import 'material-symbols/outlined.css'

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/landing" element={<Landing />} />
        <Route path="/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/completeSignUp/:token" element={<CompleteSignUp />} />
      </Routes>
    </Router>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
