import React, { useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { Editor, EditorState } from "draft-js";
import axiosInstance from "../../utils/axiosInstance";
import ReactMarkdown from 'react-markdown'
import { stateToHTML } from "draft-js-export-html";
import { add, set } from "lodash";
import { render } from "react-dom";
import CodeBlock from "./codeBlock";
import Loading from "../loading/loading";

export default function Idea({
  selectedIdea,
  setSelectedIdea,
  selectedPanel,
  subIdeas,
  setSubIdeas,
  groupId,
  getIdeas
  
}) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [newSubIdeaType, setNewSubIdeaType] = useState("SUGGESTION");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [chats, setChats] = useState([]);

  const [subIdeaAdditionEditorState, setSubIdeaAdditionEditorState] = useState(
    () => EditorState.createEmpty()
  );
  const [chatEditorState, setChatEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [loading, setLoading] = useState(false);

  const [currentlyFocusedChatId, setCurrentlyFocusedChatId] = useState(null);
  const [numSubIdeas, setNumSubIdeas] = useState(1);
  const [generateAiSubIdeasDropdown, setGenerateAiSubIdeasDropdown] =
    useState(false);
  const [focusedSubIdeaId, setFocusedSubIdeaId] = useState(null);
  const [focusedSubIdeaAdditionType, setFocusedSubIdeaAdditionType] =
    useState(null);

  const handleScroll = (e) => {
    const atTop = e.target.scrollTop === 0;
    console.log("scrolling");
    setIsScrolled(!atTop);
  };


  const renderOl = ({ children }) => {
    return <ol class="list-decimal ml-10">{children}</ol>;
  };

  const renderUl = ({ children }) => {
    return <ul class="list-disc ml-10">{children}</ul>;
  };

  const renderLi = ({ children }) => {
    return <li class="mb-2">{children}</li>;
  };

  const renderParagraph = ({ children }) => {
    return <p class="mb-1">{children}</p>;
  };

  async function handleSubIdeaNotesReplies(subIdeaId, subIdeaAdditionType) {
    setSubIdeaAdditionEditorState(() => EditorState.createEmpty());
    setFocusedSubIdeaId(null);
    setFocusedSubIdeaAdditionType(null);
    setFocusedSubIdeaId(subIdeaId);
    setFocusedSubIdeaAdditionType(subIdeaAdditionType);
  }
  async function handleReplySubmit(subIdeaId, conversationId) {
    const replyText = chatEditorState.getCurrentContent().getPlainText();
    console.log(subIdeas);
    if (replyText.length > 0) {
      await addChatMessages(conversationId, "user",  replyText);
      
  
      const response = await axiosInstance.post(
        "/api/idea/subIdeaQuestionReply",
        {
          subIdeaId: subIdeaId,
          conversationId: conversationId,
          rawContent: replyText,
        }
      );
      setChatEditorState(() => EditorState.createEmpty());

  
      await addChatMessages(conversationId, "assistant", response.data.gptResponse);
      console.log(subIdeas);
    }
  }

    React.useEffect(() => {
    console.log(subIdeas);
}, [subIdeas]);

async function handleSubIdeaNotesRepliesSubmit(subIdeaId, conversationId) {
  console.log("conversationId", conversationId);
  console.log("submitting")
  const tempId = "TMP"+subIdeaId;
  const subIdeaAdditionText = subIdeaAdditionEditorState
    .getCurrentContent()
    .getPlainText();
  addNewChat(subIdeaId, tempId, "user", subIdeaAdditionText)
  setSubIdeaAdditionEditorState(() => EditorState.createEmpty());
  setLoading(true);
  console.log(subIdeaAdditionText)
  if (focusedSubIdeaAdditionType === "REPLY") {
    if (subIdeaAdditionText.length > 0) {
      const response = await axiosInstance.post(
        "/api/idea/subIdeaQuestionReply",
        {
          subIdeaId: subIdeaId,
          conversationId: conversationId,
          rawContent: subIdeaAdditionEditorState
            .getCurrentContent()
            .getPlainText(),
        }
      );

      // Generate a temp ID
    

      // Update the id of the chat message and add the assistant message
      setChats((prevChats) => {
        return prevChats.map((chat) => {
          if (chat.id === tempId) {
            // Update ID and add the assistant message
            return {
              ...chat,
              id: response.data.newConversationId,
              content: [
                ...chat.content,
                { role: "assistant", content: response.data.gptResponse },
              ],
            };
          } else {
            return chat;
          }
        });
      });
    }
    setLoading(false);
  }
}


  useEffect(() => {
    console.log(chats);
  }, [chats]);

  const toggleSubIdeaTypes = () => {
    if (newSubIdeaType === "QUESTION") {
      setNewSubIdeaType("SUGGESTION");
    } else if (newSubIdeaType === "SUGGESTION") {
      setNewSubIdeaType("INSIGHT");
    } else if (newSubIdeaType === "INSIGHT") {
      setNewSubIdeaType("QUESTION");
    }
  };

  const editorRef = useRef();
  const subIdeaAdditionEditorRef = useRef();
  const chatEditorRef = useRef();

  const randomNumber = Math.floor(Math.random() * 1000);

  async function incrementSubideas() {
    if (numSubIdeas < 10) {
      setNumSubIdeas(numSubIdeas + 1);
    } else {
      setNumSubIdeas(1);
    }
  }

  useEffect(() => {
    if (selectedIdea && Array.isArray(selectedIdea.subIdeas)) {
      setSubIdeas(selectedIdea.subIdeas.filter((subIdea) => subIdea.deleted === false));
    }
  }, [selectedIdea]);

  async function decrementSubideas() {
    if (numSubIdeas === 1) {
      setNumSubIdeas(10);
    } else {
      setNumSubIdeas(numSubIdeas - 1);
    }
  }

  async function addSubIdea(type, rawContent, ideaId) {
    const response = await axiosInstance.post("/api/idea/createSubIdeaV2", {
      type: type,
      rawContent: rawContent,
      ideaId: ideaId,
    });
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    }
  }

  async function addGPTSubIdea(ideaId, numSubIdeas, prompt) {
    const response = await axiosInstance.post("/api/idea/addGPTSubIdea", {
      ideaId: ideaId,
      numSubIdeas: numSubIdeas,
      prompt: prompt,
      groupId: groupId,
    });
    if (response.status === 200) {
      setSubIdeas([...response.data.idea]);
    }
  }

  async function toggleAiGenSubIdeasDropdown() {
    setGenerateAiSubIdeasDropdown(!generateAiSubIdeasDropdown);
  }
  addChats();

  useEffect(() => {
    if (selectedIdea !== null && selectedIdea.subIdeas !== null) {
      console.log(selectedIdea);
      setSubIdeas([...selectedIdea.subIdeas]);
    }
  }, [selectedIdea]);

  useEffect(() => {
    console.log(chats);
  }, [chats]);

  async function handleSubideaSubmit() {
    const rawContent = editorState.getCurrentContent().getPlainText();
    const ideaId = selectedIdea.id;
    const newSubIdea = await addSubIdea(newSubIdeaType, rawContent, ideaId);
    setSubIdeas([
      {
        id: newSubIdea.idea.id,
        ideaId: newSubIdea.idea.ideaId,
        type: newSubIdeaType,
        content: rawContent,
        deleted: false,
        chats: [],
      },
      ...subIdeas,
    ]);
    console.log("newSubIdea: ", newSubIdea.idea.id);
    console.log(subIdeas);
    setEditorState(EditorState.createEmpty());
  }

  async function handleClickReply(chatId) {
    setCurrentlyFocusedChatId(chatId);
  }

  async function handleGPTSubIdeaSubmit() {
    const prompt = editorState.getCurrentContent().getPlainText();
    const ideaId = selectedIdea.id;
    await addGPTSubIdea(ideaId, numSubIdeas, prompt).then((response) => {
      // setSubIdeas([...response.idea[0][0], ...subIdeas])
      // setSubIdeas(response);
      setEditorState(EditorState.createEmpty());
      setGenerateAiSubIdeasDropdown(false);
    });
  }

  async function deleteSubIdea(subIdeaId) {
    const response = await axiosInstance.post("/api/idea/deleteSubIdea", {
      id: subIdeaId,
    });
    if (response.status === 200) {
      console.log(response.data);
      setSubIdeas(subIdeas.filter((subIdea) => subIdea.id !== subIdeaId));
    }
  }




  async function addChats() {
    console.log("adding chats" + subIdeas);
  
    // Create a Set of existing chat IDs for fast lookup
    const chatIds = new Set(chats.map(chat => chat.id));
  
    const newChats = subIdeas.flatMap((subIdea) => {
      if (subIdea && subIdea.chats) {
        return subIdea.chats.filter((chat) => {
          // Only include the chat if its ID is not in the Set
          return !chatIds.has(chat.id);
        });
      }
      return [];
    });
  
    // Only call setChats once with all the new chats
    if (newChats.length > 0) {
      setChats(prevChats => [...prevChats, ...newChats]);
    }
  }

  function addNewChat(subIdeaId, chatId, role, message) {
  setChats((prevChats) => {
      return [
        ...prevChats,
        {
          id: chatId,
          subIdeaId: subIdeaId,
          content: [{ role: role, content: message }],
        },
      ];
    });
  }


  async function addChatMessages(chatId, role, message) {
    setChats((prevChats) => {
      return prevChats.map((chat) => {
        if (chat.id === chatId) {
          return {
            ...chat,
            content: [...chat.content, { role: role, content: message }],
          };
        } else {
          return chat;
        }
      });
    });
  }

  async function addToChat(subIdeaId, chatId, role, message) {
    const newSubIdeas = subIdeas.map((subIdea) => {
      if (subIdea.id === subIdeaId) {
        return {
          ...subIdea,
          chats: subIdea.chats.map((chat) => {
            if (chat.id === chatId) {
              return {
                ...chat,
                content: [...chat.content, { role: role, content: message  }],
              };
            } else {
              return chat;
            }
          }),
        };
      } else {
        return subIdea;
      }
    });
    setSubIdeas(newSubIdeas);
  }

  async function checkIfChatExists(subIdeaId){
    const chat = chats.find(chat => chat.subIdeaId === subIdeaId)
    if (chat) {
      return true
    } else {
      return false
    }

  }

  return (
    <div className=" w-full justify-center h-full ">
      <div
        className={`px-4 pt-5 pb-3 rounded-lg shadow h-fit text-black bg-indigo-100 relative`}
      >
        {selectedIdea ? (
          <button
            className="absolute top-1 right-0 px-2 rounded-xl z-10 p-1"
            onClick={() => setSelectedIdea(null) && setSubIdeas([])}
          >
            <span className="material-symbols-outlined bg-gray-400 hover:bg-red-600 p-0.5 rounded-full">
              close
            </span>
          </button>
        ) : null}
        <div
          className={`bg-indigo-50 w-fit absolute top-2 left-4 px-2 rounded-xl z-10 ${
            isScrolled ? "ring-indigo-900 ring-1" : ""
          }`}
        >
          <h2 className={`font-semibold mb-1 text`}>{selectedIdea.title}</h2>
        </div>
        {generateAiSubIdeasDropdown ? (
          <div className="bg-white shadow flex flex-wrap items-center absolute right-5 z-50 bottom-16 m-0 p-2">
            <p className="text-lg font-semibold w-fit pr-2 ml-1 mb-0.5">
              Subideas
            </p>
            <div className="h-[20px] w-2 border-r" />
            <div className="flex items-center ml-2 bg-indigo-700 text-white rounded-lg  border-r border-black">
              <button
                className="material-symbols-outlined cursor-pointer pr-1.5 pl-2"
                onClick={decrementSubideas}
                style={{ fontSize: 14 }}
              >
                remove
              </button>
              <span className="px-1 bg-indigo-50 text-black">
                {numSubIdeas}
              </span>
              <button
                className="material-symbols-outlined cursor-pointer pr-2 pl-1.5"
                onClick={incrementSubideas}
                style={{ fontSize: 14 }}
              >
                add
              </button>
            </div>
            <div className="h-[20px] w-2 border-r" />
            <span
              className="material-symbols-outlined bg-green-600 rounded-full bottom-2 -right-0.5 cursor-pointer select-none ml-2 my-auto"
              style={{ fontSize: 24 }}
              onClick={handleGPTSubIdeaSubmit}
            >
              arrow_forward
            </span>
          </div>
        ) : null}
        <div
          className={`w-full  bg-indigo-50 relative pt-7  px-3 pb-3 rounded-lg overflow-y-hidden`}
        >
          {selectedIdea ? (
            <div className="flex flex-col h-full">
              <div className="flex-start border-b pb-2">
                {parse(selectedIdea.htmlContent)}
              </div>
              <div className="overflow-y-scroll hide-scrollbar flex-grow">
                <ul className=" border-indigo-100">
                  {subIdeas.map((subIdea) => (
                    <li
                      key={subIdea.id + "-container-" + randomNumber}
                      className="flex  flex-col my-2 border-b py-1 border-b-indigo-100"
                    >
                      <div className="flex flex-row items-center">
                        {subIdea.type === "INSIGHT" && (
                          <i className="material-symbols-outlined text-green-500">
                            magic_button
                          </i>
                        )}
                        {subIdea.type === "QUESTION" && (
                          <i className="material-symbols-outlined text-blue-500">
                            question_mark
                          </i>
                        )}
                        {subIdea.type === "SUGGESTION" && (
                          <i className="material-symbols-outlined text-yellow-500">
                            assistant
                          </i>
                        )}
                        <p
                          key={subIdea.id + "-content-" + randomNumber}
                          className="ml-2 flex-grow"
                        >
                          {parse(subIdea.content)}
                        </p>
                        <div className="flex flex-row p-1 bg-indigo-100 rounded-lg shadow self-start">
                            <i
                              className="material-symbols-outlined text-blue-500 mx-0.5 hover:text-blue-700 cursor-pointer"
                              style={{ fontSize: 20 }}
                              onClick={() =>
                                handleSubIdeaNotesReplies(subIdea.id, "REPLY")
                              }
                            >
                              reply
                            </i>
                          
                          <i
                            className="material-symbols-outlined text-orange-500 mx-0.5 hover:text-orange-700 cursor-pointer"
                            style={{ fontSize: 20 }}
                            onClick={() =>
                              handleSubIdeaNotesReplies(subIdea.id, "NOTE")
                            }
                          >
                            add
                          </i>
                          <i
                            className="material-symbols-outlined text-green-500 mx-0.5 hover:text-green-700 cursor-pointer"
                            style={{ fontSize: 20 }}
                          >
                            edit
                          </i>
                          <i
                            className="material-symbols-outlined text-red-500 mx-0.5 hover:text-red-700 cursor-pointer"
                            style={{ fontSize: 20 }}
                            onClick={() => deleteSubIdea(subIdea.id)}
                          >
                            delete
                          </i>
                        </div>
                      </div>
                      {chats.some((chat) => chat.subIdeaId === subIdea.id) &&
                        chats.map((chat, chatIndex) => (
                          (chat.subIdeaId === subIdea.id) && (
                          <div key={chatIndex} className="flex flex-row text-sm">
                            <div className="flex-grow" />
                            <div className="flex flex-col w-11/12 bg-indigo-300 p-1 rounded-lg my-2">
                              {chat.content.map(
                                (content, contentIndex, self) => {
                                  const isLastContent =
                                    contentIndex === self.length - 1;
                                  return (
                                    <>
                                      {content.role === "user" ? (
                                        <div className="flex flex-col w-full p-1 border text-end rounded-lg my-1 border-indigo-300 bg-indigo-100 shadow">
                                          <div
                                            key={`role-${contentIndex}`}
                                            className="font-semibold"
                                          >
                                            User
                                          </div>
                                          <div
                                            key={`content-${contentIndex}`}
                                          >
                                          <ReactMarkdown

                                            >
                                            {content.content}
                                          </ReactMarkdown>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="flex flex-col w-full p-1 border rounded-lg my-1 border-indigo-300 bg-indigo-200 shadow">
                                            <div
                                              key={`role-${contentIndex}`}
                                              className="font-semibold"
                                            >
                                              TMBI
                                            </div>
                                            <div
                                              key={`content-${contentIndex}`}
                                              className=""
                                            >
                                            <ReactMarkdown components={{ol:renderOl, li:renderLi, p:renderParagraph, ul:renderUl, code: CodeBlock}}>{content.content}</ReactMarkdown>
                                            </div>
                                          </div>
                                          {isLastContent &&
                                            currentlyFocusedChatId !==
                                              chat.id && (
                                              <div className="flex flex-row justify-end">
                                                <i
                                                  className="material-symbols-outlined text-white mx-0.5 cursor-pointer bg-indigo-700  hover:bg-indigo-800 rounded-full p-0.5"
                                                  style={{ fontSize: 24 }}
                                                  onClick={() =>
                                                    handleClickReply(chat.id)
                                                  }
                                                >
                                                  reply
                                                </i>
                                              </div>
                                            )}
                                          {isLastContent && currentlyFocusedChatId === chat.id && (
                                          <div
                                            className={`w-full flex-row h-full border shadow relative bg-indigo-100 rounded-lg p-1.5`}
                                          >
                                            <Editor
                                              key="chatEditor"
                                              placeholder="Enter notes or replies here"
                                              editorState={
                                                chatEditorState
                                              }
                                              onChange={
                                                setChatEditorState
                                              }
                                              ref={chatEditorRef}
                                              className="shadow-md rounded-xl overflow-y-scroll w-full hide-scrollbar"
                                            />
                                            <i
                                              className="material-symbols-outlined text-blue-500 mx-0.5 hover:text-blue-700 cursor-pointer flex absolute right-0 top-1 z-50"
                                              onClick={() =>
                                                handleReplySubmit(
                                                  subIdea.id,
                                                  chat.id
                                                )
                                              }
                                            >
                                              arrow_forward
                                            </i>
                                          </div>
                                        )}
                                        </>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                  )))}
                      {focusedSubIdeaId === subIdea.id && (
                        <div className="flex flex-row flex-grow w-full   my-2">
                          <div className="flex-grow" />
                          <div
                            className={`w-[95%] flex-row h-fit border shadow border-indigo-200 relative rounded-lg pr-5 pl-2 p-1.5`}
                          >
                            <Editor
                              key="subIdeaAdditionEditor"
                              placeholder="Enter notes or replies here"
                              editorState={subIdeaAdditionEditorState}
                              onChange={setSubIdeaAdditionEditorState}
                              ref={subIdeaAdditionEditorRef}
                              className="shadow-md rounded-xl h-fit overflow-y-scroll w-full hide-scrollbar"
                            />
                            {loading ? (<i className="animate-spin material-symbols-outlined cursor-default flex absolute right-0 top-2 z-50">progress_activity</i>) : (
                            <i
                              className="material-symbols-outlined text-blue-500 mx-0.5 hover:text-blue-700 cursor-pointer flex absolute right-0 bottom-1 z-50"
                              onClick={() =>
                                handleSubIdeaNotesRepliesSubmit(
                                  subIdea.id,
                                  null
                                )
                              }
                            >
                              arrow_forward
                            </i>
                            )}
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center border-indigo-100 py-2 overflow-y-clip w-full h-16 ">
                {newSubIdeaType === "INSIGHT" && (
                  <i
                    className="material-symbols-outlined   mt-2.5 mx-1 rounded-lg border-2 border-green-500 p-1 cursor-pointer"
                    onClick={() => toggleSubIdeaTypes()}
                  >
                    magic_button
                  </i>
                )}
                {newSubIdeaType === "QUESTION" && (
                  <i
                    className="material-symbols-outlined text-blue-500 mt-2.5 mx-1 border-2 rounded-lg border-blue-500 p-1 cursor-pointer"
                    onClick={() => toggleSubIdeaTypes()}
                  >
                    question_mark
                  </i>
                )}
                {newSubIdeaType === "SUGGESTION" && (
                  <i
                    className="material-symbols-outlined text-yellow-500 mt-2.5 mx-1 border-2 rounded-lg border-yellow-500 p-1 cursor-pointer"
                    onClick={() => toggleSubIdeaTypes()}
                  >
                    assistant
                  </i>
                )}

                <div className="flex-grow border border-indigo-200 rounded-lg min-h-fit mt-1 overflow-y-scroll hide-scrollbar p-2">
                  <Editor
                    key="titleEditor"
                    placeholder="Enter new subideas here"
                    editorState={editorState}
                    onChange={setEditorState}
                    ref={editorRef}
                    className="shadow-md rounded-xl max-h-32 overflow-y-scroll w-full hide-scrollbar"
                  />
                </div>
                <span
                  className="material-symbols-outlined bg-green-600 rounded-full cursor-pointer select-none self-center mt-2 ml-2"
                  style={{ fontSize: 32 }}
                  onClick={handleSubideaSubmit}
                >
                  arrow_forward
                </span>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center text-center text-lg border-indigo-200 text-gray-500 border-dashed border-4 h-full rounded-xl">
              <p>Select an idea to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
