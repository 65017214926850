import React from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from '../../../node_modules/react-syntax-highlighter/dist/esm/styles/prism';

const CodeCopyBtn = ({copyContent}) => (
  <button onClick={copyContent} className=' absolute top-1 right-0.5 material-symbols-outlined'>
  content_copy
  </button>
)

const CodeBlock = ({node, inline, className, children, ...props}) => {
  const match = /language-(\w+)/.exec(className || '')
  const lang = match && match[1] ? match[1] : ''
  console.log(lang)

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(children);
      alert('Copied to clipboard!');
      // You can add logic here to handle user feedback e.g. show a success message
    } catch (err) {
      console.error('Failed to copy text: ', err);
      // You can add logic here to handle user feedback e.g. show an error message
    }
  };

  return !inline && lang ? (
    <div className='relative'>
      <SyntaxHighlighter language={lang} style={vs} className="rounded-lg" wrapLongLines={true} showLineNumbers={true}>
        {children}
      </SyntaxHighlighter>
      <CodeCopyBtn copyContent={copyContent}/>
    </div>
  ) : (
    <code className={"font-semibold"} {...props}>
      {children}
    </code>
  )
}

CodeBlock.propTypes = {
  value: PropTypes.string,
  language: PropTypes.string,
};

CodeBlock.defaultProps = {
  language: null,
};

export default CodeBlock;
