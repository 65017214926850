import React, { useState, useEffect, useRef } from "react";
import { Editor, EditorState } from "draft-js";
import axiosInstance from "../../utils/axiosInstance";

function Feedback() {
  const editorRef = useRef();
  const titleEditorRef = useRef();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [titleEditorState, setTitleEditorState] = useState(() =>
    EditorState.createEmpty()
    );


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (editorState.getCurrentContent().getPlainText().length < 1 || titleEditorState.getCurrentContent().getPlainText().length < 1) {
          alert("Please enter some feedback and a title.");
          return;
        }
        const feedback = {
            title: titleEditorState.getCurrentContent().getPlainText(),
            feedback: editorState.getCurrentContent().getPlainText(),
        };
        try {
            const response = await axiosInstance.post(`/api/feedback/submitFeedback`, feedback);
            setEditorState(EditorState.createEmpty());
            setTitleEditorState(EditorState.createEmpty());
        } catch (error) {
            console.log(error);
    
        }
        };


  return (
    <div className="flex flex-col items-center  container w-full p-4 h-full  mx-auto">
      <div className="bg-indigo-100 w-full p-4 md:w-11/12 lg:w-8/12 rounded-xl shadow">
        <h2 className="font-bold text-2xl mb-4 text-center">Submit Feedback</h2>
        <div className="w-full h-12 bg-indigo-50 border-b border-indigo-200 px-4 pt-3 rounded-lg">
        <Editor
                key="titleEditor"
                placeholder="Title"
                editorState={titleEditorState}
                onChange={setTitleEditorState}
                ref={titleEditorRef}
                className="bg-p1OffWhite shadow-md rounded-xl w-full h-full overflow-y-scroll "
              />
        </div>
        <div
          className="w-full h-[20vh] md:h-[23vh] bg-indigo-50 relative px-4 pt-5 pb-2 rounded-lg cursor-text overflow-y-scroll hide-scrollbar"
          onClick={() => editorRef.current.focus()}
        >
          <Editor
            key="titleEditor"
            placeholder="Enter feedback or bug report here."
            editorState={editorState}
            onChange={setEditorState}
            ref={editorRef}
            className="shadow-md rounded-xl w-11/12 max-h-32 overflow-y-scroll absolute hide-scrollbar"
          />
        </div>
        <div className="flex justify-end"> {/* Added this div to position the button on the right */}
          <button className="p-1 mt-4 bg-indigo-700 text-white rounded-lg" onClick={handleSubmit}>Submit Feedback/Bug Report</button>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
