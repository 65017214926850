import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import Landing from './components/landing/landing';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

function App() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
      navigate('/landing');
  }, [isAuth]);
}

export default App;
