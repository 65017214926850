import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import MyIdeasDashboard from "./myIdeasDashboard";
import UnderConstruction from "./underConstruction";
import TermsAndConditions from "../legal/termsAndConditions";
import PrivacyPolicy from "../legal/privacyPolicy";
import IdeaNotebook from "../idea/ideaNotebook";
import ChangePassword from "../auth/changePassword";
import Profile from "../profile/profile";
import DashboardHome from "./dashboardHome";
import Feedback from "../feedback/feedback";
import MyGroups from "../group/myGroups";
import CreateGroup from "../group/createGroup";
import GroupInvite from "../group/groupInvite";
import Group from "../group/group";
import GroupError from "../group/groupError";
import GroupProtectedRoute from "../auth/groupProtectedRoute";

function DashboardBody({
  isMobileView,
  selectedIdea,
  setSelectedIdea,
  setIdeas,
  ideas,
  getIdeas,
  selectedPanel,
  ideaLoadingArray,
  setIdeaLoadingArray,
}) {
  const [subIdeas, setSubIdeas] = useState([]);

  return (
  <Routes>
    <Route
      path="/"
      element={
        <DashboardHome
          setSelectedIdea={setSelectedIdea}
          ideas={ideas}
          setIdeas={setIdeas}
          selectedIdea={selectedIdea}
          subIdeas={subIdeas}
          setSubIdeas={setSubIdeas}
          selectedPanel={selectedPanel}
          getIdeas={getIdeas}
          ideaLoadingArray={ideaLoadingArray}
          setIdeaLoadingArray={setIdeaLoadingArray}
        />
      }
    />
    <Route
      path="/myIdeas"
      element={
        <MyIdeasDashboard
          setSelectedIdea={setSelectedIdea}
          selectedIdea={selectedIdea}
          ideas={ideas}
          setIdeas={setIdeas}
          subIdeas={subIdeas}
          setSubIdeas={setSubIdeas}
          getIdeas={getIdeas}
          ideaLoadingArray={ideaLoadingArray}
          setIdeaLoadingArray={setIdeaLoadingArray}
        />
      }
    />
    <Route
      path="/ideaEditor"
      element={
        process.env.NODE_ENV === "development" ? (
          <IdeaNotebook />
        ) : (
          <UnderConstruction page="Idea Editor" />
        )
      }
    />

    <Route
      path="/createGroup"
      element={<CreateGroup />}
    />
    
    <Route path="/myGroups" element={
      <MyGroups />
  } 
  />
    <Route path="/changePassword" element={<ChangePassword />} />
    <Route path="/termsAndConditions" element={<TermsAndConditions />} />
    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
    <Route path="/profile" element={<Profile ideas={ideas} />} />
    <Route path="/feedback" element={<Feedback />} />
    <Route path="/groupInvite/:groupInviteToken/:groupId" element={<GroupInvite />}/>
    <Route path="/group/:groupId" element={<GroupProtectedRoute><Group /></GroupProtectedRoute>}/>

  </Routes>
  );
}

export default DashboardBody;
