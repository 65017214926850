import React, { useState, useEffect } from 'react';


function GroupError() {
    return ( 
        <div>
        <p>Error: You are not a member of this group.</p>
        </div>
     );
}

export default GroupError;