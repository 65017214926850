import { useEffect, useState } from "react";
import IdeaInput from "../idea/ideaInput";
import MyIdeas from "../idea/myIdeas";
import Idea from "../idea/idea";
import Navbar from "../nav/navbar";
import RecentUserActivity from "./recentUserActivity";
import axiosInstance from "../../utils/axiosInstance";
import DashboardBody from "./dashboardBody";
import DashboardMenuDesktop from "./dashboardMenuDesktop";
import DashboardMenuMobile from "./dashboardMenuMobile";

export default function Dashboard() {
  const [email, setEmail] = useState("");
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [ideas, setIdeas] = useState([]);
  const [numSubIdeas, setNumSubIdeas] = useState();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState("home");
  const [subIdeas, setSubIdeas] = useState([]);
  const [ideaLoadingArray, setIdeaLoadingArray] = useState("test");



  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
      console.log(isMobileView);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const getIdeas = async () => {
    try {
      const response = await axiosInstance.get(`/api/idea/getIdeas`);
      if (response.status === 200) {
        setIdeas(response.data.ideas.reverse());

      } else {
        console.log(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getIdeas();
    
  }, [subIdeas]);

  

  return (
    <>
      <div className="fixed top-0 w-full z-50">
        <Navbar selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel}/>
      </div>
      <div className="grid grid-cols-12 hide-scrollbar pt-20 h-screen/1"> 
        <div className="bg-indigo-100 hidden relative md:block md:col-span-3 lg:col-span-2 shadow-lg flex">
          <DashboardMenuDesktop selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel}/>
        </div>
        <div className={`bg-indigo-100 md:hidden shadow-lg relative ${mobileMenuOpened ? "col-span-11" : "col-span-1"} flex`}>
          <DashboardMenuMobile mobileMenuOpened={mobileMenuOpened} setMobileMenuOpened={setMobileMenuOpened} selectedPanel={selectedPanel} setSelectedPanel={setSelectedPanel}/>
        </div>
        <div className={`${mobileMenuOpened ? "hidden" : "col-span-11 md:col-span-9 lg:col-span-10"} overflow-y-scroll h-screen/1`}>
          <DashboardBody
            ideaLoadingArray={ideaLoadingArray}
            setIdeaLoadingArray={setIdeaLoadingArray}
            isMobileView={isMobileView}
            selectedIdea={selectedIdea}
            setSelectedIdea={setSelectedIdea}
            setIdeas={setIdeas}
            ideas={ideas}
            subIdeas={subIdeas}
            setSubIdeas={setSubIdeas}
            selectedPanel={selectedPanel}
            getIdeas={getIdeas} 
          />
        </div>
        
      </div>
    </>
  );
}
