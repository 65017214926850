import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import Overview from "./overview";
import ProfilePic from "./profilePic";
import axiosInstance from "../../utils/axiosInstance";
import UnderConstruction from "../dashboard/underConstruction";
import Loading from "../loading/loading";

async function countSubideas(user) {
  let subIdeasCount = 0;
  for (let i = 0; i < user.getUser.ideas.length; i++) {
    const idea = user.getUser.ideas[i];
    subIdeasCount += idea.subIdeas.length;
  }
  return subIdeasCount;
}

function Profile() {
  const { user, getUser } = useAuth();
  const [subIdeasCount, setSubIdeasCount] = useState(0);
  const [ideasCount, setIdeasCount] = useState(0);
  const [groupMembershipsCount, setGroupMembershipsCount] = useState(0);
  const [userImage, setUserImage] = useState(null);
  
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user && user.getUser) { // Add this check
      setUserImage(user.getUser.image);
      setGroupMembershipsCount(user.getUser.groupMemberships.length);
      setIdeasCount(user.getUser.ideas.length);
      setUserImage(user.getUser.userProfilePicture);
      countSubideas(user).then((count) => {
        setSubIdeasCount(count);
      });
    }
  }, [user]);
  
  const initialLetter = user && user.getUser && user.getUser.email
    ? user.getUser.email.charAt(0).toUpperCase()
    : "";

    
  if (!user || !user.getUser) {
    return <Loading />; 
  }

  return (
      <div className="flex flex-col min-h-fit h-full w-full bg-indigo-50 p-4">
      <div className="w-4/6 mx-auto flex h-fit">
        <ProfilePic
          userImage={userImage}
          initialLetter={initialLetter}
          user={user}
        />
        <Overview
          ideasCount={ideasCount}
          subIdeasCount={subIdeasCount}
          groupMembershipsCount={groupMembershipsCount}
        />
      </div>
      <div className="w-4/6 mx-auto flex-grow flex flex-col lg:flex-row space-y-2 lg:space-y-0 mt-1">
        <div className="bg-indigo-100 flex flex-col flex-grow h-full w-full lg:w-1/2 rounded-xl mb-2 mr-1 p-2">
          <h3 className="text-center font-semibold text-lg">
            My Recent Activity
          </h3>
          <div className="w-full h-full bg-indigo-50 rounded-xl mt-2 flex-grow">
          <ul>
          {user.getUser.userRecentActivity === null ? (
            <li>No recent activity</li>
          ) : (
            [...user.getUser.userRecentActivity].reverse().map((activity) => { // Make a copy and reverse it
              if (activity.activityType === "IDEA_CREATED") {
                return activity.numInitialSubideas !== 0 ? (
                  <li
                    key={activity.id}
                    className=" border-black my-2 ml-2 px-2 border-l-2 flex flex-row "
                  >
                    <p className="w-10/12 text-sm">Created idea "{activity.activityTargetIdea.title}" with{" "}
                    {activity.numInitialSubideas} subideas.</p><p className="text-xs text-gray-600">{activity.createdAt.slice(0, 10)}</p>
                  </li>
                ) : (
                  <li
                    key={activity.id}
                    className=" border-black my-2 ml-2 px-2 border-l-2 flex flex-row "
                  >
                    <p className="w-10/12 text-sm">Created idea "{activity.activityTargetIdea.title}."</p><p className="text-xs text-gray-600">{activity.createdAt.slice(0, 10)}</p>
                  </li>
                );
              } else if (activity.activityType === "IDEA_DELETED") {
                return (
                  <li
                    key={activity.id}
                    className=" border-black my-2 ml-2 px-2 border-l-2 flex flex-row "
                  >
                    <p className="w-10/12 text-sm">Deleted idea "{activity.activityTargetIdea.title}."</p><p className="text-xs text-gray-600">{activity.createdAt.slice(0, 10)}</p>
                  </li>
                );
              } 
              return null; // It's important to return null if the condition is not met
            })
          )}
        </ul>
          </div>
        </div>
        <div className="bg-indigo-100 flex flex-col flex-grow h-full w-full lg:w-1/2 rounded-xl p-2">
          <h3 className="text-center font-semibold text-lg">
            Recent Group Activity
          </h3>
          <div className="w-full h-full bg-indigo-50 rounded-xl mt-2 flex-grow"><UnderConstruction page={"Recent Group Activity"}/></div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
