// AuthProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const checkAuth = async () => {
    try {
      const response = await axiosInstance.get('/api/auth/protectedRoute');
      if (response.status === 200) {
        setIsAuth(true);
        return true;
      } else {
        setIsAuth(false);
        return false;
      }
    } catch (error) {
        
      setIsAuth(false);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    const respose = await axiosInstance.get('/api/auth/getUser');
    if (respose.status === 200) {
        setUser(respose.data);
        }
    };


  useEffect(() => {    
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuth, checkAuth, isLoading, getUser, user }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
