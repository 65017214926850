import { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ProfileIcon from "./profileIcon";
import axiosInstance from "../../utils/axiosInstance";

export default function Navbar({selectedPanel, setSelectedPanel}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const profileImageRef = useRef();
  const { getUser, user } = useAuth();
  const [userInfo, setUserInfo] = useState({ email: "NoUser", image: null, groupMemberships: [], ideas: [] });
  const [dropDownIsSelected, setDropDownIsSelected] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    const handleClickOutside = (event) => {
      // Add condition to check if the click is not on the profile image
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !profileImageRef.current.contains(event.target)) {
        setDropDownIsSelected(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  useEffect(() => {
    getUser();
  }, []);


  useEffect(() => {
    if (user && user.email !== null) {
      setUserInfo(user.getUser);
    }
  }, [user]);
  
  const initialLetter = userInfo.email ? userInfo.email.charAt(0).toUpperCase() : "";
  //

  async function signOut() {
    axiosInstance.get("api/auth/signOut")
      .then((res) => {
        window.location.reload();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }



  



  return (
    <nav className="h-20 w-full bg-indigo-700 flex items-center sticky top-0 z-40 relative">
      <Link to="/">
        <img
          src="/logo-new8.png"
          height={120}
          className="hidden md:block flex-initial h-16 w-fit mx-2"
        />
        <img
        src="/TMBIlogo_only.svg"
        height={120}
        className="block md:hidden flex-initial h-16 w-fit mx-2"
      />
      </Link>
      <div className="flex-grow" />
      <div className="flex h-full bg-indigo-700 justify-center items-center relative pr-3">
        <ProfileIcon userInfo={userInfo} dropDownIsSelected={dropDownIsSelected} setDropDownIsSelected={setDropDownIsSelected} initialLetter={initialLetter} dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} profileImageRef={profileImageRef} />
      </div>
      <div ref={dropdownRef} className={`absolute top-14 right-12 h-fit w-60 bg-white shadow-lg ${dropDownIsSelected ? '' : 'hidden'} rounded-md`}>
        <ul className="text-lg">
          <li className="text-center h-16 relative">
            <p className="text-lg font-semibold">{userInfo.email}</p>
            <div className="absolute right-0 -bottom-3 align-middle">
              <div className="h-10 flex">
                <div className="flex-start ">
                  <p className="text">{userInfo.ideas.length}</p>
                </div>
                <div className="flex-end">
                  <p
                    className="material-symbols-outlined text-blue-500"
                    style={{ marginTop: 3 }}
                  >
                    description
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute right-16 -bottom-3 align-middle">
              <div className="h-10 flex">
                <div className="flex-start ">
                  <p className="text mr-1">{userInfo.groupMemberships.length}</p>
                </div>
                <div className="flex-end">
                  <p
                    className="material-symbols-outlined text-green-500"
                    style={{ marginTop: 3 }}
                  >
                    group
                  </p>
                </div>
              </div>
            </div>
          </li>

          <li className="text-center border-t py-1 hover:bg-indigo-50 cursor-pointer select-none" onClick={() => navigate("/profile")}>Profile</li>
          <li className="text-center border-t py-1 hover:bg-indigo-50 cursor-pointer select-none" onClick={() => navigate("/changePassword")}>Change Password</li>
          <li className="text-center border-t py-1 hover:bg-indigo-50 cursor-pointer select-none rounded-b-xl" onClick={signOut}>Logout</li>
        </ul>
      </div>
    </nav>
  );
}
