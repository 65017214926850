import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import axiosInstance from "../../utils/axiosInstance";

export default function MyIdeas({ setSelectedIdea, ideas, setIdeas, ideasLoadingArray}) {

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = (e) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };
  const randomNumber = Math.floor(Math.random() * 1000);

  const deleteIdea = async (id, event) => {
    // Stop the event propagation here
    event.stopPropagation();

    try {
        setIdeas(ideas.filter((idea) => idea.id !== id));
        const response = await axiosInstance.delete(`/api/idea/deleteIdea?id=${id}`);
        if (response.status === 200) {
            console.log(response.data);
        }
    } catch (error) {
        console.log(error);
    }
};

useEffect(() => {
  console.log(ideasLoadingArray);
}, []);



  return (
    <div className="flex w-full justify-center">
      <div
        className={` px-4 pt-5 w-full pb-3 rounded-lg shadow  text-black bg-indigo-100 relative`}
      >
        <div
          className={`bg-indigo-50 w-fit absolute top-2 left-4 px-2 rounded-xl z-10 ${
            isScrolled ? "ring-indigo-900 ring-1" : ""
          }`}
        >
          <h2 className={`font-semibold mb-1 text`}>My Ideas</h2>
        </div>
        <div
          className={`w-full bg-indigo-50 relative pt-7  px-3 pb-3 rounded-lg overflow-y-auto hide-scrollbar`}
          onScroll={handleScroll}
        >
 {(ideas.length === 0) ? (
            <div className="text-center">
              <p className="text-gray-500 text-sm">No ideas yet</p>
            </div>
          ) : (
        ideas.map((idea) => (
            <div
              key={idea.id + "-container-" + randomNumber}
              className="border-y border-indigo-100 p-2 relative"
              onClick={() => setSelectedIdea(idea)}
            >
              <div
                className="hover:drop-shadow relative"
              >
                <p
                  key={idea.id + "-title-" + randomNumber}
                  className="font-semibold "
                >
                  {idea.title}
                </p>
                <div
                  key={idea.id + "-content-" + randomNumber}
                  className=" text-base pl-2 pb-4 pt-2 hidden md:block"
                >
                  {parse(idea.htmlContent)}
                </div>
          
                <div className="absolute -bottom-1 right-2 hidden md:block">
                  <p className="text-xs text-gray-500">
                    {idea.createdAt.slice(0, 10)}
                  </p>
                </div>
              </div>
              <div className="absolute top-2 right-2 hidden sm:block">
                <p 
                className="text-gray-300 text-md hover:text-red-600 material-symbols-outlined"
                style={{marginTop: 0.75}}
                onClick={(event) => deleteIdea(idea.id, event)}
                >
                  delete
                </p>
                <div className="absolute top-0 right-14">
               {idea.subIdeas.length} 
                <p className="material-symbols-outlined absolute text-orange-500 ml-0.5" style={{marginTop: 0.75}}>subtitles</p>
                </div>
              </div>
            </div>
          ))
          )}
    
        </div>
      </div>
    </div>
  );
}
