import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function Footer({ setSelectedPanel, mobileMenuOpened, setMobileMenuOpened, inMobileMenu }) {

    const toggleMobileMenu = () => {
        if (inMobileMenu) {
            console.log("inMobileMenu: ", inMobileMenu)
            setMobileMenuOpened(false);
        }
    }

    
    return ( 
        <div className="flex w-full p-3 justify-center">
            <div className="container px-4 pt-8 pb-1  h-full text-black">
                <div className="px-2 rounded-xl z-10 text-center text-xs w-full">
                <p className='hover:font-semibold' onClick={() => toggleMobileMenu()}><Link to={"/feedback"}>Feedback/Bug Report</Link></p>
                <p className='hover:font-semibold' onClick={() => toggleMobileMenu()}><Link to={"/termsAndConditions"}>Terms and Conditions</Link></p>
                <p className='hover:font-semibold' onClick={() => toggleMobileMenu()}><Link to={"/privacyPolicy"}>Privacy Policy</Link></p>
                </div>
            </div>
        </div>
     );
}

export default Footer;