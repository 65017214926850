function getCroppedImg(imageSrc, pixelCrop) {
    const imageElement = document.createElement('img');
    imageElement.src = imageSrc;
    
    return new Promise((resolve) => {
      imageElement.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          imageElement,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
    
        canvas.toBlob(resolve, 'image/jpeg');
      };
    });
  }
  
  export default getCroppedImg;