import { toBlob } from 'html-to-image';

function getCroppedImg(imageSrc, pixelCrop) {
  const imageElement = document.createElement('img');
  imageElement.src = imageSrc;

  return new Promise((resolve) => {
    imageElement.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');

      // Draw the image onto the canvas
      ctx.drawImage(
        imageElement,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      // Create a second canvas to create the round image
      const roundCanvas = document.createElement('canvas');
      roundCanvas.width = pixelCrop.width;
      roundCanvas.height = pixelCrop.height;
      const roundCtx = roundCanvas.getContext('2d');

      // Create a circular clipping path
      roundCtx.beginPath();
      roundCtx.arc(pixelCrop.width / 2, pixelCrop.height / 2, pixelCrop.width / 2, 0, 2 * Math.PI);
      roundCtx.clip();

      // Draw the original image onto the second canvas
      roundCtx.drawImage(canvas, 0, 0, pixelCrop.width, pixelCrop.height);

      roundCanvas.toBlob(resolve, 'image/jpeg');
    };
  });
}

export default getCroppedImg;
