import React, { useState, useEffect } from 'react';
import MyIdeas from '../idea/myIdeas';
import IdeaInput from '../idea/ideaInput';
import Idea from '../idea/idea';

function DashboardHome({setSelectedIdea, ideas, setIdeas, selectedIdea, subIdeas, setSubIdeas, selectedPanel, getIdeas, ideaLoadingArray, setIdeaLoadingArray}) {
    return (   
        <div className="sm:w-full md:w-5/6 mx-auto flex flex-col mt-3">
        <div className="flex flex-col w-full">
          <IdeaInput groupId={null} setIdeas={setIdeas} ideas={ideas} ideasLoadingArray={ideaLoadingArray} setIdeasLoadingArray={setIdeaLoadingArray}/>
          </div>
          { selectedIdea ? (
            <div className="flex flex-grow mt-5">
            <Idea 
              selectedIdea={selectedIdea} 
              setSelectedIdea={setSelectedIdea}
              selectedPanel={selectedPanel}  
              subIdeas={subIdeas}
              setSubIdeas={setSubIdeas}
              getIdeas={getIdeas}
            />
            </div>
          ) : (
            <div className=" flex flex-grow mt-5">
              <MyIdeas
                setSelectedIdea={setSelectedIdea}
                ideas={ideas}
                setIdeas={setIdeas}
                selectedPanel={selectedPanel}
                ideaLoadingArray={ideaLoadingArray}
                setIdeaLoadingArray={setIdeaLoadingArray}
              />
              
            </div>
          )}
        </div>
     );
}

export default DashboardHome;