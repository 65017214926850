import React, { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Editor, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import axiosInstance from "../../utils/axiosInstance";
import { encode } from "gpt-tokenizer";


export default function IdeaInput({groupId, ideas, setIdeas}) {
  const [aiTitlesSelected, setAiTitlesSelected] = useState(true);
  const [aiSubIdeasSelected, setAiSubIdeasSelected] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [typicalTokens, setTypicalTokens] = useState(0);
  const [baseTokens, setBaseTokens] = useState(0);
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [numSubIdeas, setNumSubIdeas] = useState(3);
  const [ideasLoadingCount, setIdeasLoadingCount] = useState(0);
  const [mainEditorState, setMainEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [titleEditorState, setTitleEditorState] = useState(() =>
    EditorState.createEmpty()
  );



  

  const mainEditorRef = useRef();
  const titleEditorRef = useRef();

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        onUserStoppedTyping();
      }, 250)
    );
  }, [mainEditorState]);

  const mainEditorText = mainEditorState.getCurrentContent().getPlainText();
  const onUserStoppedTyping = useCallback(() => {
    if (aiSubIdeasSelected || aiTitlesSelected) {
      const tokenizedText = encode(mainEditorText);
      setTokens(tokenizedText);
    }
  }, [aiSubIdeasSelected, aiTitlesSelected, mainEditorText]);

  const calculateBaseTokens = useCallback(() => {
    let baseTokens;
    let typicalBaseTokens;

    if (aiSubIdeasSelected && aiTitlesSelected) {
      baseTokens = 125 + 250;
      typicalBaseTokens = 125 + 43 + (numSubIdeas - 1) * (29 - 8); // (prompt + (1 subidea + 1 title)) + ((n subideas - 1) * 29 tokens per subidea - 8 tokens per subidea for the domain)
    } else if (aiSubIdeasSelected && !aiTitlesSelected) {
      baseTokens = 113 + 200;
      typicalBaseTokens = 113 + 29 + (numSubIdeas - 1) * (29 - 8); // (prompt + 1 subidea) + ((n subideas - 1) * 29 tokens per subidea - 8 tokens for domain)
    } else if (!aiSubIdeasSelected && aiTitlesSelected) {
      baseTokens = 76 + 50;
      typicalBaseTokens = 76 + 18;
    } else {
      baseTokens = 0;
      typicalBaseTokens = 0;
    }

    // Ensure that typicalBaseTokens never exceeds baseTokens
    if (typicalBaseTokens > baseTokens) {
      typicalBaseTokens = baseTokens;
    }

    setBaseTokens(baseTokens);
    setTypicalTokens(typicalBaseTokens);
  }, [aiTitlesSelected, aiSubIdeasSelected, numSubIdeas]);



  async function incrementSubideas() {
    if (numSubIdeas < 10) {
      setNumSubIdeas(numSubIdeas + 1);
    } else {
      setNumSubIdeas(1);
    }
  }

  async function decrementSubideas() {
    if (numSubIdeas === 1) {
      setNumSubIdeas(10);
    } else {
      setNumSubIdeas(numSubIdeas - 1);
    }
  }

  useEffect(() => {
    calculateBaseTokens();
  }, [calculateBaseTokens, numSubIdeas]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mainEditorState.getCurrentContent().getPlainText().length < 10) {
      alert("Please enter an idea.");
      return;
    }
    const ideav2 = {
      groupId: groupId,
      rawContent: mainEditorState.getCurrentContent().getPlainText(),
      htmlContent: stateToHTML(mainEditorState.getCurrentContent()),
    };
  
    try {
        setMainEditorState(EditorState.createEmpty());
        setTitleEditorState(EditorState.createEmpty());
        setIdeasLoadingCount((count) => count + 1);
        const response = await axiosInstance.post(`/api/idea/createIdeav2`, ideav2);
  
        // Use a function to update state
        setIdeas((prevIdeas) => [response.data.idea,...prevIdeas])
        setIdeasLoadingCount((count) => count - 1);

  
    } catch (error) {
        console.log(error);
    }
  };


        


  return (
    <div className="w-full h-fit relative">
      <div className=" px-4 pt-8 pb-1 rounded-lg shadow h-fit text-black bg-indigo-100 relative ">
        <div className="bg-indigo-50 w-fit absolute top-4 left-4 px-2 rounded-xl z-10">
          <h2 className="font-semibold mb-1 text">Idea Input</h2>
        </div>
        <div className="absolute top-0 right-2">
 
      </div>
      {!aiTitlesSelected ? (
      <div className="w-full h-12 bg-indigo-50 border-b border-indigo-200 px-4 pt-3 rounded-r-lg rounded-bl-lg">
      <Editor
              key="titleEditor"
              placeholder="Title"
              editorState={titleEditorState}
              onChange={setTitleEditorState}
              ref={titleEditorRef}
              className="bg-p1OffWhite shadow-md rounded-xl w-full h-full overflow-y-scroll "
            />
      </div>
      ): ""}

        <div
          className="w-full h-40 bg-indigo-50 relative px-4 pt-5 pb-2 rounded-lg cursor-text overflow-y-scroll hide-scrollbar"
          onClick={() => mainEditorRef.current.focus()}
        >
          <Editor
            key="mainEditor"
            placeholder={!isEditorFocused ? "Write your idea here..." : ""}
            editorState={mainEditorState}
            onChange={setMainEditorState}
            onFocus={() => setIsEditorFocused(true)}
            onBlur={() => setIsEditorFocused(false)}
            ref={mainEditorRef}
            className="bg-p1OffWhite shadow rounded-xl w-full -mt-4  "
          />
        </div>
        <span className="flex mt-2">
          <label> 
           
          
          
          </label>
          &nbsp;
            &nbsp; &nbsp;
          {aiSubIdeasSelected ? (
            <div className="flex items-center ml-2 bg-indigo-700 text-white rounded-lg">
              <button
                className="material-symbols-outlined cursor-pointer md:pr-1.5 md:pl-2"
                onClick={decrementSubideas}
                style={{ fontSize: 14 }}
              >
                remove
              </button>
              <span className="px-1 bg-indigo-50 text-black">{numSubIdeas}</span>
              <button
                className="material-symbols-outlined cursor-pointer md:pr-2 md:pl-1.5"
                onClick={incrementSubideas}
                style={{ fontSize: 14 }}
              >
                add
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="flex-grow" />
        </span>
      {ideasLoadingCount > 0  &&
        <div className="absolute bottom-1 left-2 flex items-center justify-center">
        <p className="material-symbols-outlined animate-spin inline" style={{fontSize: 18}}>
            progress_activity
        </p>
        <p className="inline text-sm">
            {`Proccessing ${(ideasLoadingCount === 1) ? (ideasLoadingCount + " idea") : (ideasLoadingCount + " ideas")}.`}
        </p>
    </div>}
        
        <button className="text-white absolute -bottom-4 -right-2 z-50">
          <span
            style={{ fontSize: 40 }}
            className="material-symbols-outlined m-1 rounded-full p-0.5 bg-indigo-700 hover:ring hover:ring-indigo-900"
            onClick={handleSubmit}
          >
            arrow_forward
          </span>
        </button>
      </div>
    </div>
  );
}
