import React, { useState, useEffect } from 'react';

function TermsAndConditions() {
    return ( 
        <div className="container mx-auto  w-5/6 text-black p-2 rounded-md">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p className='mb-4 font-semibold'> Effective Date: May 15, 2023</p>
            <p className="mb-4">Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern TMBI.xyz's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">1. Service Description</h2>
            <p className="mb-4">This website provides a platform to assist users in developing their ideas by assigning titles and sub-ideas. The website utilizes a token system, where the token use is proportionate to the length of the provided prompt and the subsequent response.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">2. User Obligations</h2>
            <p className="mb-4">Users are expected to use the service for its intended purpose. Misuse, which includes but is not limited to, entering content that is not an idea, resulting in an invalid response, is strictly prohibited. You acknowledge and agree that you are solely responsible for the form, content, and accuracy of any material you post on the website or provide to other users.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">3. Token Use</h2>
            <p className="mb-4">The baseline token use is proportionate to the number of tokens used in the prompt. You understand and agree that we are not responsible for token use due to misuse or inappropriate use of the tool. We will make reasonable efforts to limit the response in the event of an invalid input, but we will not be held liable for token use due to inappropriate use of the service.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">4. Limitation of Liability</h2>
            <p className="mb-4">To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the services.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">5. Changes to Terms</h2>
            <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

            <h2 className="text-xl font-bold mt-4 mb-2">6. Governing Law</h2>
            <p className="mb-4">These Terms shall be governed and construed in accordance with the laws of the location where our company is headquartered, without regard to its conflict of law provisions.</p>

            <p className="mt-6">By using our services, you consent to the Terms of Service and agree to comply with them. If you have any questions about these Terms, please contact us.</p>
            </div>
     );
}

export default TermsAndConditions;