import axios from 'axios';

let axiosInstance;

if (process.env.NODE_ENV === 'development') {
    axiosInstance = axios.create({
        baseURL: '',
        withCredentials: true
    });
} else {
    axiosInstance = axios.create({
        baseURL: 'https://api.tmbi.xyz/',
        withCredentials: true,
    });
}


export default axiosInstance;