import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { Editor, EditorState } from "draft-js";
import { debounce, set } from "lodash";
import ReactModal from "react-modal";
import AsyncSelect from "react-select/async";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropGroupImage";
import { useAuth } from "../../context/AuthContext";

function CreateGroup() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [currentlyTypedMember, setCurrentlyTypedMember] = useState("");
  const [matchingEmails, setMatchingEmails] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { getUser } = useAuth();
  const [showPreview, setShowPreview] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedValue, setSelectedValue] = useState(null);
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();

  const handleUploadImageClick = (event) => {
    event.stopPropagation();
    setCroppedImage(null);
    hiddenFileInput.current.value = null; // Reset input file value
    hiddenFileInput.current.click();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createCroppedImagePreview = async () => {
    const croppedImageBlob = await getCroppedImg(
      selectedFile,
      croppedAreaPixels
    );
    setCroppedImageBlob(croppedImageBlob);
    setCroppedImage(URL.createObjectURL(croppedImageBlob));
    setShowPreview(true);
  };

  function toggleModal() {
    setShowModal(!showModal);
  }

  const submitCrop = async () => {
    completeCrop();
    console.log(croppedImage);
  };

  const handleScroll = (e) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };
  const editorRef = useRef();
  const currentlyTypedMemberRef = useRef(currentlyTypedMember);

  useEffect(() => {
    currentlyTypedMemberRef.current = currentlyTypedMember;
  }, [currentlyTypedMember]);

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("groupName", groupName);
    formData.append(
      "groupDescription",
      editorState.getCurrentContent().getPlainText()
    );
    formData.append("groupEmails", members);
    formData.append("groupImage", croppedImageBlob);

    const response = await axiosInstance.post(
      "/api/group/createGroup",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.status === 200) {
      console.log(response.data);
      clearInput();
      getUser();
      navigate("/group/" + response.data.id);
    }
  };
  function closeModal() {
    setShowModal(false);
    setShowPreview(false);
    setSelectedFile(null);
    setCroppedImage(null);
    hiddenFileInput.current.value = ""; // Reset input file value
  }
  function completeCrop() {
    setShowModal(false);
    setShowPreview(false);
    setSelectedFile(null);
    hiddenFileInput.current.value = ""; // Reset input file value
  }

  function clearInput() {
    setCurrentlyTypedMember("");
    setMatchingEmails([]);
    setEditorState(EditorState.createEmpty());
    setMembers([]);
    setGroupName("");
    setSelectedFile(null);
    setImage(null);
    setCroppedImage(null);
    setShowPreview(false);
    hiddenFileInput.current.value = ""; // Reset input file value
  }

  const findMatchingEmails = async (partialEmail) => {
    if (partialEmail) {
      const response = await axiosInstance.post(
        "/api/auth/findMatchingEmails",
        {
          partialEmail: partialEmail,
        }
      );
      if (response.status === 200) {
        let matchingEmailsArray = [];
        response.data.matchingEmails.map((email) => {
          matchingEmailsArray.push(email.email);
        });
        console.log(matchingEmailsArray);
        console.log(response.data.matchingEmails);
        return matchingEmailsArray;
      }
    }
    return [];
  };

  const debouncedFetchEmails = useCallback(
    debounce(findMatchingEmails, 500),
    []
  );
  useEffect(() => {
    if (currentlyTypedMember.length > 0) {
      debouncedFetchEmails(currentlyTypedMember);
    } else {
      debouncedFetchEmails.cancel(); // Cancel the debounce if the input is empty
      setMatchingEmails([]); // Clear the matchingEmails list
    }
  }, [currentlyTypedMember, debouncedFetchEmails]);

  const handleFileChange = async (event) => {
    console.log("setting selected file");
    if (event.target.files.length > 0) {
      setSelectedFile(URL.createObjectURL(event.target.files[0]));
      console.log("set selected file");
    }
    event.target.value = null; // Reset input file value
    console.log("reset input file value");
  };

  useEffect(() => {
    if (selectedFile) {
      setShowModal(true);
      console.log("selected file changed:", selectedFile);
    }
  }, [selectedFile]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const loadOptions = (inputValue, callback) => {
    console.log(inputValue);
    findMatchingEmails(inputValue).then((results) => {
      console.log(results); // Log results to verify
      callback(results.map((email) => ({ label: email, value: email })));
    });
  };
  const handleInputChange = (newValue) => {
    setCurrentlyTypedMember(newValue);
  };

  const handleAddMember = (newValue) => {
    if (newValue === null || validateEmail(newValue.value) === false) {
      alert("Please enter a valid email address.");
      return;
    }
    setMembers([...members, newValue.value]);
    setCurrentlyTypedMember("");
    setSelectedValue(null); // Clear the selected value
  };

  return (
    <div className="flex flex-col min-h-fit h-full w-full bg-indigo-50 p-4">
      <div className="w-full lg:w-4/6 mx-auto flex rounded-xl bg-indigo-100 relative ">
        <div
          className={`bg-indigo-50  w-fit absolute top-2 left-4 px-2 rounded-xl z-10 ${
            isScrolled ? "ring-indigo-900 ring-1" : ""
          }`}
        >
          <h2 className={`font-semibold mb-1 text`}>Create Group</h2>
        </div>
        <div className="flex flex-col w-full mx-4 mt-6 mb-4 flex-grow bg-indigo-50">
          <form className="flex flex-col w-full">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <div
                  className={`w-40 h-40 rounded-lg  ${
                    !croppedImage && "border-4 border-dashed border-indigo-300"
                  } m-4 flex items-center justify-center text-center cursor-pointer`}
                  onClick={handleUploadImageClick}
                >
                  {!croppedImage ? (
                    <label
                      className="font-semibold text-indigo-300 cursor-pointer"
                      onClick={handleUploadImageClick}
                    >
                      Upload a group image
                    </label>
                  ) : (
                    <img
                      src={croppedImage}
                      className="w-40 h-40 rounded-lg   m-4 flex items-center justify-center text-center cursor-pointer"
                      onClick={handleUploadImageClick}
                    />
                  )}
                  <input
                    className="hidden"
                    type="file"
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                    accept="image/*"
                    capture="filesystem"
                  />
                </div>
                <div className="flex flex-col flex-grow mt-4">
                  <label className="text-sm font-semibold text-gray-600 ml-2">
                    Group Name
                  </label>
                  <input
                    className="border border-gray-300 p-2 rounded-lg  focus:outline-none m-2"
                    type="text"
                    placeholder="Group Name"
                    onChange={(e) => setGroupName(e.target.value)}
                  />

                  <label className="text-sm font-semibold  text-gray-600 ml-2">
                    Add Members
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedValue}
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={handleAddMember}
                    placeholder={
                      <p className="inputTextPlaceholder">
                        Enter email address
                      </p>
                    }
                    unstyled={true}
                    classNames={{
                      control: () =>
                        "border border-gray-300 p-2 rounded-lg bg-white focus:outline-none m-2",
                      option: () => "text-gray-900 bg-white",
                      menu: () =>
                        "text-gray-900 bg-white z-50 -mt-1 p-1 w-60 rounded-lg mx-auto",
                      option: () =>
                        "text-gray-900 bg-white hover:bg-indigo-200 cursor-pointer my-1 p-1 rounded-lg",
                    }}
                    styles={{
                      menu: () => ({ width: "98%" }),
                    }}
                  />

                  <div className="flex flex-row flex-wrap">
                    {members.map((member) => (
                      <>
                        <div className="bg-indigo-200 rounded-full px-2 py-1 m-1">
                          {member}
                          <div
                            className="bg-red-600 rounded-full material-symbols-outlined cursor-pointer align-middle ml-2"
                            style={{ fontSize: 16 }}
                            onClick={() =>
                              setMembers(members.filter((m) => m !== member))
                            }
                          >
                            close
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <label className="text-sm font-semibold text-gray-600 ml-2">
                Group Description
              </label>
              <div className=" mt-1 bg-white h-60 m-2 rounded-lg border-gray-300  border p-2">
                <div className={`${showModal && "hidden"}`}>
                  <Editor
                    key="titleEditor"
                    placeholder="Enter a group description."
                    editorState={editorState}
                    onChange={setEditorState}
                    ref={editorRef}
                    className={`shadow-md rounded-xl w-11/12 max-h-32 overflow-y-scroll absolute hide-scrollbar z-0`}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-end">
                <button
                  className="bg-indigo-700 text-white font-semibold px-4 py-2 rounded-lg shadow-lg hover:bg-indigo-600 transition duration-200 mr-3 mb-1"
                  onClick={handleSubmit}
                >
                  Create Group
                </button>
              </div>
            </div>
          </form>
          <ReactModal
            isOpen={showModal}
            onRequestClose={closeModal}
            ariaHideApp={false}
            className={{
              base: "modal-content",
              afterOpen: "",
              beforeClose: "",
            }}
          >
            {showPreview ? (
              <>
                <div className="h-80 relative">
                  <div>
                    <img
                      src={croppedImage}
                      alt="Cropped"
                      className="h-80 mx-auto rounded-lg"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="relative h-80">
                  <Cropper
                    image={selectedFile}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              </>
            )}

            <button
              onClick={closeModal}
              className="p-1 bg-red-500 hover:bg-red-600 text-white font-semibold top-1 right-1 absolute material-symbols-outlined rounded-full"
            >
              Close
            </button>
            {showPreview ? (
              <div className=" w-full flex mt-2">
                <button
                  onClick={() => setShowPreview(false)}
                  className="py-1 px-2 rounded-md bg-indigo-700 text-white font-semibold"
                >
                  Back
                </button>
                <div className="flex-grow" />
                <button
                  onClick={submitCrop}
                  className="py-1 px-2 rounded-md bg-indigo-700 text-white font-semibold"
                >
                  Submit
                </button>
              </div>
            ) : (
              <div className=" w-full flex mt-2">
                <div className="flex-grow" />
                <button
                  onClick={createCroppedImagePreview}
                  className="py-1 px-2 rounded-md bg-indigo-700 text-white font-semibold z-50\"
                >
                  Preview
                </button>
              </div>
            )}
          </ReactModal>
        </div>
      </div>
    </div>
  );
}
export default CreateGroup;
