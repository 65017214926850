import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from  '../../utils/axiosInstance';


export default function CompleteSignUp() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const [validToken, setValidToken] = useState(false);
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordSpecial, setPasswordSpecial] = useState(false);
  const [signupComplete, setSignupComplete] = useState(false);

  const navigate = useNavigate();

  console.log(token);

  useEffect(() => {
    if (signupComplete) {
        navigate('/');
    }
  }, [signupComplete]);



  useEffect(() => {
    async function validateToken() {
      try {
        const validateToken = await axiosInstance.get(`/api/auth/validateSignUpToken?token=${token}`);
        if (validateToken.data.validateSignUpToken === true) {
          setValidToken(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
    validateToken();
    console.log(validateToken.data);
  }, [token]);

  async function handleCompleteSignup (event) {
    event.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/api/auth/completeSignUp`,
        { password, token }
      );
      if (response.status === 200) {
        console.log(response.data);
        setSignupComplete(true);
        return true;
      } else {
        console.log(response.data.error);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  
  const passwordValidation = (password, confirmPassword) => {
    setPasswordMatches(password === confirmPassword);
    setPasswordLength(password.length >= 8);
    setPasswordNumber(/\d/.test(password));
    setPasswordSpecial(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password));
  }

  useEffect(() => {
    passwordValidation(password, confirmPassword);
  }, [password, confirmPassword]);

  return (
    <div className="bg-cover bg-center h-screen min-w-full items-center container bg-indigo-700">
      <div className="items-center w-full  bg-indigo-700 pt-10 md:pt-20 p-6 ">
      <img src="/logo-new8.png" className="  mx-auto md:w-1/3" />     </div>
      <div className="text-3xl text-white sm:w-3/4 md:w-1/4 sm:mx-10 md:mx-auto text-center md:mt-10">

      {validToken ? (
        <>
        <h2 className="font-bold text-2xl mb-8 mt-10">Set your password</h2>
        <form>
          <input 
            type="password" 
            placeholder="Password" 
            className="w-full p-2 rounded-md mb-4 text-sm text-black mx-auto" 
            onChange={(e) => setPassword(e.target.value)}
          />
          <input 
            type="password" 
            placeholder="Confirm Password" 
            className="w-full p-2 rounded-md text-sm text-black mx-auto" 
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {!(passwordMatches && passwordLength && passwordNumber && passwordSpecial) && (
          <p className='text-sm text-center p-2 bg-white text-black w-fit mx-auto mt-2 rounded-xl'>
            Your password should: 
            <span className={passwordMatches ? 'text-green-500' : 'text-red-500'}> match,</span>
            <span className={passwordLength ? 'text-green-500' : 'text-red-500'}> be at least 8 characters long,</span>
            <span className={passwordNumber ? 'text-green-500' : 'text-red-500'}> contain at least one number,</span> and
            <span className={passwordSpecial ? 'text-green-500' : 'text-red-500'}> contain at least one special character.</span>
          </p>
          )}
          <button 
            type="submit" 
            className="p-2 text-sm  w-fit bg-indigo-900 text-white rounded-md hover:shadow-lg mt-4"
            onClick={handleCompleteSignup}
            disabled={!(passwordMatches && passwordLength && passwordNumber && passwordSpecial)}
          >
            Submit
          </button>
        </form>
        </>
    ) : (
        <>
        <h2 className="font-bold text-2xl mb-8 mt-20">Invalid sign-in link.</h2>
        <p className="text-white">Please check your email for the correct link.</p>
        </>
      )}
        </div>
      </div>
  );
}
