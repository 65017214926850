import React, { useState, useEffect } from "react";

function Overview({ideasCount, subIdeasCount, groupMembershipsCount}) {
  return (
    <div className=" ml-2 flex-grow h-60  bg-indigo-100 p-4 h-full rounded-xl hidden lg:flex">
      <div className="flex flex-col bg-orange-500 w-1/3  rounded-xl">
        <header className="flex flex-col justify-center items-center p-2  h-full ">
          <h1 className=" text-7xl font-bold">{ideasCount}</h1>
          <br />
          <h2 className="text-4xl font-semibold">Ideas</h2>
        </header>
      </div>
      <div className="w-1/3 bg-green-500 mx-4 rounded-xl p-1">
        <header className="flex flex-col justify-center items-center p-2  h-full ">
          <h1 className=" text-7xl font-bold">{subIdeasCount}</h1>
          <br />
          <h2 className="text-4xl font-semibold">Subideas</h2>
        </header>
      </div>
      <div className="flex flex-col w-1/3 bg-red-500 rounded-xl">
        <header className="flex flex-col justify-center items-center p-2  h-full ">
          <h1 className=" text-7xl font-bold">{groupMembershipsCount}</h1>
          <br />
          <h2 className="text-4xl font-semibold">Groups</h2>
        </header>
      </div>
    </div>
  );
}

export default Overview;
