import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useHistory } from "react-router-dom";


function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordMatches, setPasswordMatches] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordSpecial, setPasswordSpecial] = useState(false);

  const passwordValidation = (newPassword, confirmNewPassword) => {
    setPasswordMatches(newPassword === confirmNewPassword);
    setPasswordLength(newPassword.length >= 8);
    setPasswordNumber(/\d/.test(newPassword));
    setPasswordSpecial(
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(newPassword)
    );
  };
  useEffect(() => {
    passwordValidation(newPassword, confirmNewPassword);
  }, [newPassword, confirmNewPassword]);
  function handleChangePassword(e) {
    e.preventDefault();
    axiosInstance
      .post("api/auth/changePassword", {
        oldPassword: currentPassword,
        newPassword: newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
      }})
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="flex flex-col items-center justify-center container w-full p-4 h-full  mx-auto">
      <div className="bg-indigo-100 p-4 md:w-3/5 lg:w-1/3 rounded-xl shadow">
        <h2 className="font-bold text-2xl mb-4 text-center">
          Change your password
        </h2>
        <form>
          <label className="text-sm text-black">Current Password</label>
          <input
            type="password"
            placeholder="Current Password"
            className="w-full p-2 rounded-md mb-3 text-sm text-black mx-auto"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <label className="text-sm text-black">New Password</label>

          <input
            type="password"
            placeholder="Password"
            className="w-full p-2 rounded-md mb-1 text-sm text-black mx-auto"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label className="text-sm text-black">Confirm New Password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            className="w-full p-2 rounded-md text-sm text-black mx-auto"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          {!(
            passwordMatches &&
            passwordLength &&
            passwordNumber &&
            passwordSpecial
          ) && (
            <p className="text-sm text-center p-2 bg-white text-black w-fit mx-auto mt-8 rounded-xl">
              Your password should:
              <span
                className={passwordMatches ? "text-green-500" : "text-red-500"}
              >
                {" "}
                match the confirm password,
              </span>
              <span
                className={passwordLength ? "text-green-500" : "text-red-500"}
              >
                {" "}
                be at least 8 characters long,
              </span>
              <span
                className={passwordNumber ? "text-green-500" : "text-red-500"}
              >
                {" "}
                contain at least one number,
              </span>{" "}
              and
              <span
                className={passwordSpecial ? "text-green-500" : "text-red-500"}
              >
                {" "}
                contain at least one special character.
              </span>
            </p>
          )}
          <button
            type="submit"
            className="p-2 text-sm  w-full bg-indigo-900 text-white rounded-md hover:shadow-lg mt-4"
            onClick={handleChangePassword}
            disabled={
              !(
                passwordMatches &&
                passwordLength &&
                passwordNumber &&
                passwordSpecial
              )
            }
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
