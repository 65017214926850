import React, { useState, useEffect } from "react";
import Footer from "../nav/footer";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Loading from "../loading/loading";

function DashboardMenuDesktop({ setSelectedPanel, selectedPanel }) {
  const { getUser, user } = useAuth();
  const inMobileMenu = false;
  useEffect(() => {
  }, [selectedPanel]);

  let location = useLocation();

  if (!user) {
    return <Loading />;
  }
  const groupMembership = user.getUser.groupMemberships;
  console.log(groupMembership)

  return (
    <>
    <div className="hidden md:block">
      <h1 className=" text-2xl pl-2 mt-4 font-semibold w-full">Dashboard</h1>
      <div className="w-11/12 mx-auto h-5 border-b border-indigo-200  " />

      <div className="text-lg  text-gray-700">
        <ul className="pl-4 mt-4 font-semibold ">
        <li className={`mt-2 ${location.pathname === "/" ? "font-bold":""}`} onClick={() => setSelectedPanel("home")} >
        <Link to={"/"}>Home</Link>
        </li>
        </ul>
        <ul className="pl-4 mt-4 font-semibold ">
          <li className={`mt-2 ${(location.pathname === "/myIdeas" || location.pathname === "/ideaEditor") ? "font-bold":""}`} onClick={() => setSelectedPanel("myIdeas")}>
          <Link to={"myIdeas"}>Ideas</Link>
            </li>
            <ul className="ml-6 font-normal">
              <li className={`mt-2 ${location.pathname === "/myIdeas" ? "font-bold":""}`} onClick={() => setSelectedPanel("myIdeas")}><Link to={"myIdeas"}>My Ideas</Link></li>
              <li className={`mt-2 ${process.env.NODE_ENV !== "development" && "hidden" } ${location.pathname === "/ideaEditor" ? "font-bold":""}`} onClick={() => setSelectedPanel("ideaEditor")}><Link to={"ideaEditor"}>Idea Editor</Link></li>
            </ul>
        </ul>
        <ul className="pl-4 mt-4 font-semibold ">
          <li className={`mt-2 ${(location.pathname === "/myGroups" || location.pathname === "/createGroup") ? "font-bold":""}`}
          onClick={() => setSelectedPanel("myGroups")}><Link to={"myGroups"}>Groups</Link></li>
          <ul className={`ml-6  font-normal`}>
            <li className={`mt-2 ${location.pathname === "/myGroups" ? "font-bold":""}`} onClick={() => setSelectedPanel("myGroups")}><Link to={"myGroups"}>My Groups</Link>
            {groupMembership.length !== 0 &&
            <ul className="ml-3 font-normal">
              {groupMembership.map((groupMembership) => (
                <li className={`flex flex-row text-lg my-1 items-center`}>
                {(groupMembership.group.groupImage === "" || groupMembership.group.groupImage === null) ? <div className="w-10 h-10 bg-indigo-100 mr-1  bg-gradient-to-r from-green-500 to-teal-500 rounded-lg"/> : <img className="w-10 h-10 mr-1 rounded-lg" src={`https://tmbi.s3.us-east-1.amazonaws.com/${groupMembership.group.groupImage}`} alt="group"/>}
                <Link to={`/group/${groupMembership.group.id}`} className={`${location.pathname === `/group/${groupMembership.group.id}` && "font-bold"}`}>{groupMembership.group.name}</Link>
                </li>
              ))}
            </ul>
              }
            
            </li>
            <li className={`mt-2 ${location.pathname === "/createGroup" ? "font-bold":""}`} onClick={() => setSelectedPanel("createGroup")}><Link to={"createGroup"}>Create a Group</Link></li>
          </ul>
        </ul>
      </div>
      </div>
      <div className="absolute bottom-0 w-full"><Footer setSelectedPanel={setSelectedPanel} inMobileMenu={inMobileMenu}/></div>
      </>
  );
}

export default DashboardMenuDesktop;
