import React, { useState, useEffect, cloneElement } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import Loading from '../loading/loading';
import GroupError from '../group/groupError';


function GroupProtectedRoute({children}) {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [role, setRole] = useState(null);

  async function verifyGroupMembership() {
    try {
      const response = await axiosInstance.get(`/api/group/validateGroupMembership/${groupId}`);
      if (response.status === 200) {
        setIsMember(true);
        setRole(response.data.validateGroupMembership.role);  
        setLoading(false);
      } else {
        console.log(response.data.error);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
     verifyGroupMembership();
  }, [groupId]);

  if (loading) {
    return <Loading />;
  }

  // clone children element and pass additional props
  const childrenWithRoleProp = React.Children.map(children, child => 
    React.cloneElement(child, { role: role })
  );

  return isMember ? childrenWithRoleProp : <GroupError />;
}

export default GroupProtectedRoute;