import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";

function GroupInvite() {
  const { groupInviteToken, groupId } = useParams();
  const [group, setGroup] = useState({});

  async function getGroup() {
    try {
      const response = await axiosInstance.get(
        `/api/group/getGroup/${groupId}`
      );
      if (response.status === 200) {
        console.log(response.data);
        setGroup(response.data);
        return response.data;
      } else {
        console.log(response.data.error);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const acceptGroupInvite = async (e) => {
    e.preventDefault();
    const response = await axiosInstance.get(`/api/group/acceptGroupInvite/${groupInviteToken}`)
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    } else {
      console.log(response.data.error);
      return response.data.error;
    }
  };

  const declineGroupInvite = async (e) => {
    e.preventDefault();
    const response = await axiosInstance.get(`/api/group/declineGroupInvite/${groupInviteToken}`)
    if (response.status === 200) {
        console.log(response.data);
        return response.data;
    } else {
        console.log(response.data.error);
        return response.data.error;
    }
    };


  useEffect(() => {
    getGroup();
    console.log(group.getGroup.name);
  }, []);

  return (
    <div className="flex flex-col min-h-fit h-full w-full bg-indigo-50 p-4">
      <div className="w-4/6 mx-auto flex h-fit">
        <div className="w-full h-full bg-indigo-100 rounded-lg shadow-lg p-4">
          <div className="flex flex-col h-full bg-indigo-50 rounded-lg text-center">
            <h1 className="text-xl text-black">
              {" "}
              You have been invited to the group "{group.getGroup.name}"
            </h1>
            <div>
              <button
                className="bg-indigo-300 rounded-lg shadow-lg p-2 m-2 w-40 hover:bg-indigo-400"
                onClick={acceptGroupInvite}
              >
                Accept
              </button>
              <button 
                className="bg-indigo-300 rounded-lg shadow-lg p-2 m-2 w-40 hover:bg-indigo-400"
                onClick={declineGroupInvite}
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupInvite;
