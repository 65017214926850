import React, { useState, useEffect } from 'react';


function UnderConstruction({page}) 
{
    return (
        <div className="flex w-full p-3 justify-center">
            <div className="container px-4 pt-8 pb-1  h-full text-black">
            <span className="material-symbols-outlined w-full text-center" style={{fontSize:"240px"}}>
            construction
            </span>
                <div className="px-2 rounded-xl z-10 text-center text-2xl w-full">
                {page} is under construction
                </div>
            </div>
        </div>

      );
}

export default UnderConstruction;
    
    




