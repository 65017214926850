import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import IdeaInput from "../idea/ideaInput";
import axiosInstance from "../../utils/axiosInstance";
import Loading from "../loading/loading";
import GroupIdeas from "./groupIdeas";
import Idea from "../idea/idea";
import { select } from "slate";

function Group({ role }) {
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [descriptionTextExpanded, setDescriptionTextExpanded] = useState(false);
  const [showDescriptionReadMore, setShowDescriptionReadMore] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [subIdeas, setSubIdeas] = useState([]);

  async function getGroup() {
    const response = await axiosInstance.get(`/api/group/getGroup/${groupId}`);
    setGroup(response.data);
  }

  useEffect(() => {
    getGroup();
  }, []);

  useEffect(() => {
    console.log("Selected group idea: ", selectedIdea);
  }, [selectedIdea]);

  useEffect(() => {
    const element = document.getElementById("group-description");
    if (element && element.offsetHeight < element.scrollHeight) {
      setShowDescriptionReadMore(true);
    } else {
      setShowDescriptionReadMore(false);
    }
  }, [group]);
  if (!group) {
    return <Loading />;
  }

  function toggleDescriptionReadMore() {
    setDescriptionTextExpanded(!descriptionTextExpanded);
  }

  const handleScroll = (e) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };

  return (
    <div className="flex flex-col w-5/6 mx-auto  bg-indigo-50">
      <div className="grid grid-cols-12 min-h-fit w-full flex-grow">
        <div className="bg-indigo-100 col-span-12 md:col-span-5 lg:col-span-3 mt-3 rounded-lg shadow flex-grow flex flex-col">
          <img
            src={`https://tmbi.s3.us-east-1.amazonaws.com/${group.getGroup.groupImage}`}
            className=" w-11/12 mx-auto  rounded-lg mx-4 mt-3"
          />
          <div className="p-2">
            <h1 className="text font-semibold text-center">
              {group.getGroup.name}
            </h1>
            <p
              className={`${
                descriptionTextExpanded === false && "line-clamp-3"
              } text-sm`}
              id="group-description"
            >
              {group.getGroup.description}
            </p>
            {showDescriptionReadMore &&
              (descriptionTextExpanded === false ? (
                <p
                  className="text-xs text-end hover:font-semibold"
                  onClick={toggleDescriptionReadMore}
                >
                  Read more
                </p>
              ) : (
                <p
                  className="text-xs text-end hover:font-semibold"
                  onClick={toggleDescriptionReadMore}
                >
                  Read less
                </p>
              ))}
            <ul className="mt-2 mx-2 border-t border-indigo-200">
              <li className="first-letter:uppercase text-sm">{role}</li>
              <li className="first-letter:uppercase text-sm flex items-center">
                <p
                  className="inline material-symbols-outlined"
                  style={{ fontSize: 20 }}
                >
                  group
                </p>
                {group.getGroup.groupMembers.length}
              </li>
              <li className="first-letter:uppercase text-sm flex items-center">
                <p
                  className="inline material-symbols-outlined"
                  style={{ fontSize: 20 }}
                >
                  subtitles
                </p>
                {group.getGroup.ideas.length}
              </li>
            </ul>
          </div>
        </div>

        <div className="rounded-lg md:ml-2 col-span-12 md:col-span-7 lg:col-span-9 flex flex-col">
          <div className="flex-grow grid grid-cols-2">
            <div className="mt-3 bg-indigo-100 px-2 py-0.5 mr-1 shadow flex flex-col rounded-lg min-h-fit pt-5 flex-grow col-span-2 lg:col-span-1 relative">
              <div className=" h-56 bg-indigo-50 p-2 rounded-lg m-1 flex flex-col flex-grow ">
                <div
                  className={`bg-indigo-50 w-fit absolute top-2 left-3 px-2 rounded-xl z-10 ${
                    isScrolled ? "ring-indigo-900 ring-1" : ""
                  }`}
                >
                  <h2 className={`font-semibold mb-1 text`}>Activity</h2>
                </div>
                <div className="flex-grow" onScroll={handleScroll}>
                  <ul>
                    {group.getGroup.groupRecentActivity.map((activity) => (
                      <li className="flex items-center">
                        {activity.activityType === "GROUP_IDEA_CREATED" && (
                          <>
                            <div className="h-10 aspect-square mr-3">
                              <img
                                src={`https://tmbi.s3.us-east-1.amazonaws.com/${activity.user.userProfilePicture}`}
                                className="h-full mx-auto rounded-lg"
                              />
                            </div>
                            <p className="text-sm">
                              {activity.user.email} created an idea with{" "}
                              {activity.numInitialInsights} insights,{" "}
                              {activity.numInitialQuestions} questions, and{" "}
                              {activity.numInitialSuggestions} suggestions.
                            </p>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-3 bg-indigo-100 px-2 ml-1 py-0.5 shadow flex flex-col rounded-lg min-h-fit pt-5 flex-grow col-span-2 lg:col-span-1 relative">
              <div className=" h-56 bg-indigo-50 p-2 rounded-lg m-1 flex flex-col flex-grow ">
                <div
                  className={`bg-indigo-50 w-fit absolute top-2 left-3 px-2 rounded-xl z-10 ${
                    isScrolled ? "ring-indigo-900 ring-1" : ""
                  }`}
                >
                  <h2 className={`font-semibold mb-1 text`}> Members</h2>
                </div>
                <div className="flex-grow" onScroll={handleScroll}>
                  <ul>
                    {group.getGroup.groupMembers.map((groupMember) => (
                      <li className="flex pt-2">
                        <>
                          <div className="flex flex-row">
                            <div className="h-10 aspect-square mr-3">
                              <img
                                src={`https://tmbi.s3.us-east-1.amazonaws.com/${groupMember.user.userProfilePicture}`}
                                className="h-full mx-auto rounded-lg"
                              />
                            </div>
                            
                            <div className="flex flex-col">
                              <p className="text-sm font-semibold">{groupMember.email}</p>
                              <p className="text-sm first-letter:uppercase">{groupMember.role}</p>
                            </div>
                          </div>
                        </>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          
          </div>
          <div className=" flex flex-col mt-3">
          <IdeaInput groupId={groupId} />
        </div>
        </div>
      </div>
      <div className="mt-4 mb-2">
        {selectedIdea === null ? (
          <GroupIdeas
            group={group}
            selectedIdea={selectedIdea}
            setSelectedIdea={setSelectedIdea}
          />
        ) : (
          <Idea
            selectedIdea={selectedIdea}
            setSelectedIdea={setSelectedIdea}
            subIdeas={subIdeas}
            setSubIdeas={setSubIdeas}
          />
        )}
      </div>
    </div>
  );
}

export default Group;
