import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Landing from '../landing/landing';
import { useAuth } from '../../context/AuthContext'; // Import our custom hook
import Loading from '../loading/loading';


const ProtectedRoute = ({ children }) => {
  const { isAuth, checkAuth, isLoading, getUser, } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth && !isLoading) {
      checkAuth();
      navigate('/landing');
    }
  }, [isAuth, navigate, isLoading, checkAuth]);


  useEffect(() => {

    if (!isAuth && !isLoading) {
      checkAuth();
    }

  }, [isAuth, checkAuth, isLoading]);

  if (isLoading) {
    return <Loading />;
  }


  return isAuth && !isLoading ? children : <Landing />; 


};




export default ProtectedRoute;
