import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";


function MyGroups() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const { getUser, user } = useAuth();

  const handleScroll = (e) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-full lg:w-4/6 p-3 mx-auto">
      <div
        className={`flex-1 px-4 pt-5 pb-3 rounded-lg shadow overflow-y-auto bg-indigo-100 relative`}
        onScroll={handleScroll}
      >
        <div
          className={`bg-indigo-50 w-fit absolute top-2 left-4 px-2 rounded-xl z-10 ${
            isScrolled ? "ring-indigo-900 ring-1" : ""
          }`}
        >
          <h2 className={`font-semibold mb-1 text`}>My Groups</h2>
        </div>
        <div
          className={`w-full pt-7 px-3 pb-3 rounded-lg overflow-y-auto hide-scrollbar bg-indigo-50 relative h-full`}
        >
          <ul>
            {user.getUser.groupMemberships.length !== 0 ? (user.getUser.groupMemberships.map((group) => (
              <li key={group.id}>
                <div className="flex border-y border-indigo-100 p-2 h-24 relative" onClick={() => navigate("/group/" + group.groupId)}>
                  <div className=" aspect-square mr-4 mt-1 max-h-16  flex-col bg-gradient-to-r from-green-500 to-teal-500 rounded-lg" >{(group.group.groupImage === "" || group.group.groupImage === null) ? null : <img src={`https://tmbi.s3.us-east-1.amazonaws.com/${group.group.groupImage}`}/>}</div>
                  <div className="flex-col hover:drop-shadow relative text">
                    <p className="font-semibold text">{group.group.name}</p>
                    <p className="overflow-y-scroll hide-scrollbar max-h-12" >{group.group.description}</p>
                  </div>
                </div>
              </li>
              
            ))) : ( 
              <li className="text-center">
                   <p className="text-sm text-gray-500">You have not joined or created any groups</p>
              </li>
              )}
          </ul>
        </div>
      </div>
      {/* my groups above, group activity below */}
      <div
        className={`flex-1 px-4 pt-5 pb-3 rounded-lg shadow overflow-y-auto bg-indigo-100 relative mt-2`}
        onScroll={handleScroll}
      >
        <div
          className={`bg-indigo-50 w-fit absolute top-2 left-4 px-2 rounded-xl z-10 ${
            isScrolled ? "ring-indigo-900 ring-1" : ""
          }`}
        >
          <h2 className={`font-semibold mb-1 text`}>Group Activity</h2>
        </div>
        <div className={`w-full pt-7 px-3 pb-3 rounded-lg overflow-y-auto hide-scrollbar h-full bg-indigo-50 relative`}>
        
        </div>
      </div>
    </div>
  );
}

export default MyGroups;
