import React, { useState, useRef, useCallback } from "react";
import axiosInstance from "../../utils/axiosInstance";
import ReactModal from "react-modal";
import Cropper from "react-easy-crop";
import getCroppedImg from './cropImage';  // getCroppedImg function which will create a new cropped image blob.
import { useAuth } from "../../context/AuthContext";

function ProfilePic({ userImage, initialLetter, user }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null)
  const [showPreview, setShowPreview] = useState(false)
  const { getUser } = useAuth();

  const handleEditClick = (event) => {
    hiddenFileInput.current.click();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleFileChange = async (event) => {
    setSelectedFile(URL.createObjectURL(event.target.files[0]));
    setShowModal(true);
  };

  const createCroppedImagePreview = async () => {
    const croppedImageBlob = await getCroppedImg(selectedFile, croppedAreaPixels);
    setCroppedImage(URL.createObjectURL(croppedImageBlob));
    setShowPreview(true);
  }

  const submitCrop = async () => {
    const croppedImageBlob = await getCroppedImg(
      selectedFile,
      croppedAreaPixels
    );
    const formData = new FormData();
    formData.append("photo", croppedImageBlob);
    formData.append("cropDetails", JSON.stringify(croppedAreaPixels));
    try {
      const res = await axiosInstance.post("/api/upload/uploadImage", formData);
      console.log(res);
      await getUser();
    } catch (error) {
      console.log(error);
    }
    toggleModal();
  };

  function closeModal() {
    setShowModal(false);
    setShowPreview(false);
    setSelectedFile(null);
    setCroppedImage(null);
    hiddenFileInput.current.value = ''; // Reset input file value
}



  function toggleModal() {
    setShowModal(!showModal);

  }

  return (
    <>
      <div className="min-h-fit flex-grow lg:flex-none">
        <div className="lg:flex-col w-full lg:w-60 h-full bg-indigo-100 p-4 rounded-xl mx-auto">
          {userImage === null ? (
            <div className="relative">
              <h1
                className={`flex items-center justify-center hover:outline hover:outline-indigo-900 text-center text-8xl bg-amber-400 h-40 w-40 rounded-full mx-auto`}
              >
                {initialLetter}
              </h1>
              <div
                className="material-symbols-outlined absolute top-1 right-1 bg-indigo-400 p-0.5 opacity-25 hover:opacity-50 rounded"
                style={{ fontSize: 18 }}
                onClick={handleEditClick}
              >
                edit
              </div>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide this
              />
            </div>
          ) : (
            <div className="relative">
              <img
                src={`https://tmbi.s3.us-east-1.amazonaws.com/${userImage}`}
                className="rounded-full"
              />
              <div
                className="material-symbols-outlined absolute top-1 right-1 bg-indigo-400 p-0.5 opacity-25 hover:opacity-50 rounded"
                style={{ fontSize: 18 }}
                onClick={handleEditClick}
              >
                edit
              </div>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide this
              />
            </div>
          )}
          <div className="flex flex-row ">
            <h3 className="text font-semibold w-full text-center mt-5">
              {user.getUser.email}
            </h3>
          </div>
        </div>
      </div>
      <ReactModal
      isOpen={showModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          height: "45%",
          overflow: "hidden",
          relative: "true",
        },
      }}
      
    >
      {showPreview ? (
        <>
        <div className="h-80 relative">
           <div>
           <img src={croppedImage} alt="Cropped" className="rounded-full h-80 mx-auto"/>
           </div>
        </div>
        </>
      ) : (
        <>
        <div className="relative h-80">
          <Cropper
            image={selectedFile}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>

       
        </>
      )}

      <button onClick={closeModal} className="p-1 bg-red-500 hover:bg-red-600 text-white font-semibold top-1 right-1 absolute material-symbols-outlined rounded-full"> 
      Close
    </button>
    {showPreview ? (
      
      <div className=" w-full flex mt-2">
      <button onClick={() => setShowPreview(false)} className="py-1 px-2 rounded-md bg-indigo-700 text-white font-semibold">Back</button>
      <div className="flex-grow"/>
      <button onClick={submitCrop} className="py-1 px-2 rounded-md bg-indigo-700 text-white font-semibold">Submit</button>
      </div>
    ) : (
      <div className=" w-full flex mt-2">
      <div className="flex-grow"/>
      <button onClick={createCroppedImagePreview} className="py-1 px-2 rounded-md bg-indigo-700 text-white font-semibold z-50\">Preview</button>
      </div>
    )
    }
   
    </ReactModal>
  </>
  );
}

export default ProfilePic;
