import React, { useState, useEffect } from 'react';


function Loading() {
    return ( 

        <div className="flex justify-center items-center h-screen/1">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"> </div>
        </div>
     );
}

export default Loading;