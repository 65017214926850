import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from "../../context/AuthContext";

const AuthCheck = ({ children }) => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isAuth) {
      console.log("isAuth is true");
      navigate("/");
    } else {
      setShouldRender(true);  // Only allow rendering if not authenticated
    }
  }, [isAuth, navigate]);

  return shouldRender ? children : null;
};

export default function Landing() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [signInPasswordError, setSignInPasswordError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [emailExists, setEmailExists] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { isAuth, checkAuth } = useAuth();
  const navigate = useNavigate();

  const TMBI_meanings = [
    "Too Many Big Ideas",
    "Too Many Business Ideas",
    "Transforming My Best Ideas",
    "Tapping More Brainstorming Insights",
    "Transcending Mediocre Brainstorming Ideals",
    "Transmuting My Brilliant Inventions",
    "Triggering More Bright Ideas",
    "Total Mind Blowing Innovations",
    "The Master Brainstorming Interface",
    "The Mind-Boggling Innovations",
    "Transforming Mediocre to Brilliant Ideas",
    "The Mighty Brainstorming Instrument",
    "Thriving Minds Breed Innovation",
    "Thoughts Manifesting Beyond Imagination",
    "Turn My Brainstorms Into reality",
    "Translating Mental Blueprints into Innovation",
    "The Most Beneficial Ideas",
    "Tremendous Momentum in Brainstorming Ideas",
    "Tantalizing Minds with Bright Ideas",
    "Trust in More Brilliant Ideas",
    "Teaching Minds to Breed Innovation",
    "Treasure Map to Bold Ideas",
    "The Motivated Brainstorming Initiative",
    "The Multifaceted Brainstorming Instrument",
    "The Mind's Best Imaginings",
    "Top Minds Brewing Ideas",
    "Thinking More, Building Ideas",
    "Translating My Best Insights",
  ];

  const startSignup = async (email) => {
    try {
      const response = await axiosInstance.post(
        `/api/auth/signUp`,
        { email }
      );

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await axiosInstance.post(
        `/api/auth/checkEmailExists`,
        { email }
      );
      if (response.data.emailExists === true) {
        console.log(response);
        return true;
      } else {
        console.log(response);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {// Begin loading when checking auth
      const response = await axiosInstance.post(
        `/api/auth/login`,
        { email, password }
      );
        console.log(response);
        if (response.status === 200) {
        console.log("response.status === 200");
        await checkAuth().then( () => {
            navigate("/");
        });
        console.log("finished checkAuth");

 // End loading when finished checking auth

        return true;
      } else {
        console.log(response.data.error);
        return false;
      }
    } catch (error) {
      if (error.response.status === 500) {
        setSignInPasswordError("Incorrect password");
      }
      console.log(error.response.status);
      return false;
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError(""); // reset the error message if the email is valid
    }
    
    try {
      const exists = await checkEmailExists(email);
      if (exists) {
        setEmailExists(true);  // Add this line
      } else {
        setEmailExists(false);
        const success = await startSignup(email);
        if (success) {
          setEmailSent(true);
          console.log("Email sent")
        } else {
          setEmailSent(false);
        }
      }
    } catch (error) {
      console.log('Error in handleSubmit:', error);
    }
  };
  
  return (
    <AuthCheck>
    <div className="bg-cover bg-center h-screen min-w-screen container">
      <div className="items-center min-h-full w-screen  bg-indigo-700 pt-10 md:pt-20 p-10 ">
        <img src={"./logo-new8.png"} className="mx-auto max-w-full md:w-1/3" />
        <div className=" text-white text-xl sm:w-3/4 md:w-1/4 sm:mx-10 md:mx-auto text-center md:mt-10">
          {windowWidth >= 768 && (
            <Typewriter
            className="w-1/2"
              onInit={(typewriter) => {
                // Create a copy of the array and shuffle it
                let shuffledTMBI_meanings = [...TMBI_meanings];
                for (let i = shuffledTMBI_meanings.length - 1; i > 0; i--) {
                  let j = Math.floor(Math.random() * (i + 1));
                  [shuffledTMBI_meanings[i], shuffledTMBI_meanings[j]] = [
                    shuffledTMBI_meanings[j],
                    shuffledTMBI_meanings[i],
                  ];
                }

                const typeWriterAction = async () => {
                  for (let i = 0; i < shuffledTMBI_meanings.length; i++) {
                    const words = shuffledTMBI_meanings[i].split(" ");
                    for (let word of words) {
                      const boldedWord = `<b>${word.charAt(0)}</b>${word.slice(
                        1
                      )}`;
                      typewriter.typeString(boldedWord + " ").pauseFor(500);
                      await typewriter.start();
                    }
                    typewriter.pauseFor(1500);
                    await typewriter.start();

                    // Deleting all the characters except TMBI
                    for (
                      let j = 0;
                      j < shuffledTMBI_meanings[i].length - 4;
                      j++
                    ) {
                      typewriter.deleteChars(1);
                      await typewriter.start();
                    }

                    typewriter.deleteAll();
                    await typewriter.start();
                  }
                };
                typeWriterAction();
              }}
            />
          )}

          {emailSent ? (
            <h2 className="mt-20">Check your email to complete sign-up.</h2>
          ) : (
            <form>
              <>
                <h2 className="font-bold text-xl mb-8 mt-10">
                  Enter your email to get started
                </h2>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full p-2 rounded-md text-black mx-auto text-sm"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                  <p className="text-red-500 text-start text-sm italic">
                    {emailError}
                  </p>
                )}
                {emailExists ? (
                  <>
                    <input
                      type="password"
                      placeholder="Password"
                      className="w-full p-2 rounded-md text-black text-sm mx-auto mt-4"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-red-500 text-start text-sm ">
                        {signInPasswordError}
                    </p>
                    <button
                      type="submit"
                      className="p-2 w-full mx-auto text-xl bg-indigo-900 text-white rounded-md hover:shadow-lg mt-4"
                      onClick={handleLogin}
                    >
                      Submit
                    </button>
            
                  </>
                ) : (
                  <button
                    type="submit"
                    className="p-2 w-full mx-auto bg-indigo-900 text-white rounded-md hover:shadow-lg mt-4"
                    onClick={handleSubmit}
                  >
                    Get Started
                  </button>
                )}
              </>
            </form>
          )}
        </div>
      </div>
    </div>
    </AuthCheck>
  );
}

